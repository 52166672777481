import { Alert, Button, Col, Divider, Form, Input, InputNumber, Modal, Row } from "antd"
import React, { useState } from "react"
import { useStore } from "../../store";
import { normalize_key } from "../../util";
import NoImage from "../../assets/NO_IMAGE.png";

type VendSetModalProps = {
  visible: boolean,
  confirmLoading: boolean,
  error: string | undefined,
  onOk: (values: { [s: string]: { tex?: string, price?: number }; }) => void,
  onCancel: () => void
}

export const VendSetModal = ({ visible, confirmLoading, error, onOk, onCancel }: VendSetModalProps) => {
  const selection = useStore(state => state.diagram.selection);
  const [values, setValues] = useState<{ [s: string]: { tex?: string, price?: number }; }>({});
  const [initItems, setInitItems] = useState(0);
  const [filter, setFilter] = useState("");
  const loose_v4 = new RegExp(/^[0-9A-F]{8}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{12}$/i);

  if(!visible) {
    if(initItems !== 0) {
      setInitItems(0);
    }
    return (<></>);
  }

  const onChangeFilter = (s: string) => {
    setFilter((s ?? "").toLowerCase());
  };

  const onChangePrice = (k: string, v: number) => {
    if(isNaN(v)) v = 0;
    let updated = {...values};
    updated[k].price = v || 0;

    setValues(updated);
  };

  const onChangeTex = (k: string, v: string) => {
    let updated = {...values};
    updated[k].tex = v;
    setValues(updated);
  };

  const forcePrices = (v: number) => {
    let updated: { [s: string]: { tex?: string, price?: number }; } = {...values};

    Object.entries(values).forEach(item => {
        updated[item[0]].price = v;
    });
    
    setValues(updated);
  };

  if(selection[0].props.items !== undefined) {
    const entries = Object.entries(selection[0].props.items);
    if(entries.length > 0 && initItems === 0) {
      setValues(Object.fromEntries(
        entries.map(item => 
          [normalize_key(item[0]), { tex: item[1].tex || "", price: item[1].price || 0 }]
        )
      ));
      setInitItems(1);
    }
  }

  return (
    <>
      <Modal
          title="Vendor Settings"
          visible={visible}
          onOk={() => onOk(values)}
          confirmLoading={confirmLoading}
          onCancel={onCancel}
          maskClosable={false}
        >
          <Alert className="drop-form-error" style={{display: typeof error === "string" ? "" : "none"}} message={error} type="error" showIcon />
          <Form
          name="basic"
          preserve={false}
          initialValues={values}
        >
          <Input
            placeholder="Type to Search"
            allowClear
            onChange={(e) => onChangeFilter(e.target.value)}
            style={{marginTop: "8px"}}
          />
          <div style={{marginTop: "12px"}}>
            <Button type="primary" size="small" onClick={(e) => forcePrices(0)}>
              Zero
            </Button>
          </div>
          <Divider style={{margin: '12px 0'}}/>
          {Object.entries(values).filter((item) => filter === "" || item[0].toLowerCase().includes(filter)).map(item => (
            <Form.Item key={item[0]}>
              <div style={{marginBottom: "6px"}}>{item[0]}</div>
              <small>Texture</small>
              <Row>
                <Col span={19}>
                  <Input
                      onChange={(e) => onChangeTex(item[0], e.target.value)}
                      value={item[1].tex || ""}
                    />
                </Col>
                <Col span={5}>
                  <img alt="Second Life Texture Preview" width="48" height="48"
                    id={item[0]+"-tex-prev"} style={{marginTop: "-8px", marginLeft: "36px" }}
                    src={item[1].tex && loose_v4.test(item[1].tex) ? `https://secondlife.com/app/image/${item[1].tex}/1` : NoImage}>
                  </img>
                </Col>
              </Row>
              <small>Price</small>
              <Row>
                <span style={{ lineHeight: "32px" }}>L$</span>
                <InputNumber
                      min={0}
                      max={2147483647}
                      step={1}
                      style={{ width: '6rem' }}
                      value={item[1].price || 0}
                      onChange={(num: string | number | undefined) => onChangePrice(item[0], Number(num ?? 0))}
                    />
              </Row>
            </Form.Item>
          ))}
        </Form>
      </Modal>
    </>
  )
}