import { Button, Checkbox } from "antd";
import { AxiosError } from "axios";
import React, { useState } from "react"
import { VoucherNodeModel } from "../../diagramming/nodes/voucher";
import { useActions, useStore } from "../../store";
import { normalize_key, sanitize_key } from "../../util";
import { DropRateModal } from "./dropratemodal";
import { PushRedelModal } from "./pushredelmodal";
import { VendSetModal } from "./vendsetmodal";

export const BalancerContextMenu = () => {
  const setVoucherItem = useActions(state => state.api.setVoucherItem);
  const droprates = useActions(state => state.api.droprates);
  const vendset = useActions(state => state.api.vendset);
  const pushRedelivery = useActions(state => state.api.pushRedelivery);
  const jwt = useStore(state => state.api.jwt);
  const nodes = useStore(state => state.diagram.nodes);
  const model = useStore(state => state.diagram.model);
  const selection = useStore(state => state.diagram.selection);
  const [asnChecked, setAsnChecked] = useState(true);

  const [visible, setVisible] = useState(false);
  const [confirmDrmLoading, setConfirmDrmLoading] = useState(false);
  const [confirmAsvLoading, setConfirmAsvLoading] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);

  const [redelVisible, setRedelVisible] = useState(false);
  const [confirmRedelLoading, setConfirmRedelLoading] = useState(false);
  const [redelError, setRedelError] = useState<string | undefined>(undefined);

  const [vendVisible, setVendVisible] = useState(false);
  const [confirmVendLoading, setVendConfirmLoading] = useState(false);
  const [vendError, setVendError] = useState<string | undefined>(undefined);
  let sdrDisabled = false;
  
  if(selection.length === 0) {
    return (<></>);
  }

  const lockDiagram = () => {
    model.setLocked(true);
  };

  const unlockDiagram = () => {
    model.setLocked(false);
  };

  const asvDisabled = !selection[0].links.some(
    link =>
    (nodes[link.from] && nodes[link.from].type === VoucherNodeModel.NODE_TYPE)
      || (link.to && nodes[link.to] && nodes[link.to].type === VoucherNodeModel.NODE_TYPE)
  ) && !selection[0].props.items;
  

  const onAsnChanged = (e: any) => {
    setAsnChecked(e.target.checked);
  }

  const setVouchers = () => {
    if(!selection[0].props.items) return;
    let i = 0;
    let todo = 0;
    let vouchers = selection[0].links.map(link => {
      if(nodes[link.from] && nodes[link.from].type === VoucherNodeModel.NODE_TYPE) {
        todo += 1;
        return link.from;
      } else if(link.to && nodes[link.to] && nodes[link.to].type === VoucherNodeModel.NODE_TYPE) {
        todo += 1;
        return link.to;
      } else {
        return undefined;
      }
    });
    if(todo > 0) {
      setConfirmAsvLoading(true);
      vouchers.forEach(link => {
        if(!selection[0].props.items) return;
        if(link) {
          const newItem = Object.keys(selection[0].props.items)[i];
          setVoucherItem({jwt, id: link, item: normalize_key(newItem), as_name: asnChecked}).finally(() => {
            todo -= 1;
            nodes[link].props.item = newItem;
            if(todo === 0) {
              setConfirmAsvLoading(false);
            }
          });
          i += 1;
        }
      });
    }
  };

  const showRedelModal = () => {
    setRedelVisible(true);
    lockDiagram();
  };

  const onRedelCancel = () => {
    setRedelVisible(false);
    unlockDiagram();
    setRedelError(undefined);
    setConfirmRedelLoading(false);
  };

  const onRedelOk = (values: { unit: string, oldItem: string, newItem: string, fnc: boolean }) => {
    setConfirmRedelLoading(true);
    setRedelError(undefined);
    
    pushRedelivery({jwt, unit: values.unit, oldItem: values.oldItem, newItem: values.newItem, fnc: values.fnc }).then((_response: number) => {
      setRedelVisible(false);
      setConfirmRedelLoading(false);
      model.setLocked(false);
    }).catch((error: AxiosError) => {
      setConfirmRedelLoading(false);
      if(error.response) {
        setRedelError(!error.response.data ? "Something went wrong! Please try again." : (error.response.data + "!"))
      } else {
        setRedelError("Something went wrong! Please try again.");
      }
    });
  };

  const showModal = () => {
    setVisible(true);
    lockDiagram();
  };

  const onCancel = () => {
    setVisible(false);
    unlockDiagram();
    setError(undefined);
    setConfirmDrmLoading(false);
  };

  const onOk = (values: { [s: string]: number; }) => {
    setConfirmDrmLoading(true);
    setError(undefined);
    droprates({jwt, unit: selection[0].id, chances: values}).then((response: number) => {
      if(selection[0].props.items !== undefined) {
        for(const item of Object.entries(values)) {
          selection[0].props.items[sanitize_key(item[0])].chance = item[1];
        }
      }
      setVisible(false);
      setConfirmDrmLoading(false);
      model.setLocked(false);
    }).catch((error: AxiosError) => {
      setConfirmDrmLoading(false);
      if(error.response) {
        setError(!error.response.data ? "Something went wrong! Please try again." : (error.response.data + "!"))
      } else {
        setError("Something went wrong! Please try again.");
      }
    });
  };

  
  const showVendModal = () => {
    setVendVisible(true);
    lockDiagram();
  };

  const onVendCancel = () => {
    setVendVisible(false);
    unlockDiagram();
    setVendError(undefined);
    setVendConfirmLoading(false);
  };

  const onVendOk = (values: { [s: string]: { tex?: string, price?: number }; }) => {
    setVendConfirmLoading(true);
    setVendError(undefined);

    vendset({jwt, unit: selection[0].id, settings: values}).then((_response: number) => {
      if(selection[0].props.items !== undefined) {
        for(const item of Object.entries(values)) {
          const k = sanitize_key(item[0]);
          selection[0].props.items[k]["price"] = item[1].price;
          selection[0].props.items[k]["tex"] = item[1].tex;
        }
      }
      setVendVisible(false);
      setVendConfirmLoading(false);
      model.setLocked(false);
    }).catch((error: AxiosError) => {
      setVendConfirmLoading(false);
      if(error.response) {
        setVendError(!error.response.data ? "Something went wrong! Please try again." : (error.response.data + "!"))
      } else {
        setVendError("Something went wrong! Please try again.");
      }
    });
  };


  if(selection[0].props.items !== undefined) {
    const entries = Object.entries(selection[0].props.items);
    sdrDisabled = entries.length === 0;
  } else {
    sdrDisabled = true;
  }
  
  return (
    <>
      <VendSetModal
        visible={vendVisible}
        confirmLoading={confirmVendLoading}
        error={vendError}
        onOk={onVendOk}
        onCancel={onVendCancel}
      />
      <DropRateModal
        visible={visible}
        confirmLoading={confirmDrmLoading}
        error={error}
        onOk={onOk}
        onCancel={onCancel}
      />
      <PushRedelModal
        visible={redelVisible}
        confirmLoading={confirmRedelLoading}
        error={redelError}
        onOk={onRedelOk}
        onCancel={onRedelCancel}
        dUnit={selection[0].id}
      />

      <h1>General Properties</h1>
      <div className="property"><div className="label">Region:</div><div className="value">{selection[0].props.region}</div></div>
      
      <h1>Item Properties</h1>
      <Button
        type="primary"
        block
        onClick={showVendModal}
        disabled={sdrDisabled}>
        Vendor Settings...
      </Button>
      <Button type="primary" block onClick={showModal} disabled={sdrDisabled}>
        Set Drop Rates...
      </Button>

      <h1>Voucher Properties</h1>
      <div className="property">
        <div className="label">Autoset Names:</div>
        <div className="value"><Checkbox disabled={asvDisabled} checked={asnChecked} onChange={onAsnChanged} /></div>
      </div>
      <Button type="primary" block onClick={setVouchers} disabled={asvDisabled} loading={confirmAsvLoading}>
        Autoset Vouchers
      </Button>

      <h1>Maintenance</h1>
      <Button type="primary" block onClick={showRedelModal} disabled={sdrDisabled}>
        Push Redelivery...
      </Button>
    </>
  )
}