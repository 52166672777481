import { BaseModelOptions } from '@gacha/gacha-diagrams/canvas';

export interface StateModelOptions extends BaseModelOptions {
  stateId: string;
}

export const PERM_COPY = 0x00008000;
export const PERM_MODIFY = 0x00004000;
export const PERM_TRANSFER = 0x00002000;

// gacha:
// 0x1 = online/offline
// 0x2 = new items
// 0x4 = new url
// 0x8 = send usage data next tick
// 0x10 = balancers > 0
// 0x20 = distributor
// 0x40 = owner only mode
// 0x80 = debit perms
// 0x100 = free plan
// 0x200 = enable bal flag when data sent
// 0x400 = diable bal flag when data sent
// 0x800 = force disable
export const FLAG_OWNERONLY = 0x40;
export const FLAG_DISABLED = 0x800;

export * from './gacha';
export * from './distributor';
export * from './balancer';
export * from './voucher';
export * from './vendor';