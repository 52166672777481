import React from 'react';
import styled, { useTheme } from 'styled-components';
import { DiagramEngine } from '@gacha/gacha-diagrams';
import { AbstractReactFactory, GenerateWidgetEvent } from '@gacha/gacha-diagrams/canvas';
import { GenericNodeWidgetProps, NodeType, PortType } from '../util';
import NodeWidget from '../NodeWidget';
import { Theme } from '../../theme';
import { ConstrainedPortModel } from '../ports';
import { GachaNodeModel } from './gacha';
import { PERM_COPY, PERM_MODIFY, PERM_TRANSFER, StateModelOptions } from '.';
import { useNodeStateListeners } from '../util/hooks';
import { VoucherNodeModel } from './voucher';
import { StateNodeModel } from '../StateNodeModel';
import { NodeChildItem, NodeChildList } from '../styles';
import { useStore } from '../../store';
import { normalize_key } from '../../util';

export interface BalancerNodeModelOptions extends StateModelOptions { }

export class BalancerNodeModel extends StateNodeModel {
  static NODE_TYPE = 'balancer-node';
  stateOptions: BalancerNodeModelOptions;

  constructor(options: BalancerNodeModelOptions) {
    super({
      ...options,
      type: BalancerNodeModel.NODE_TYPE
    });
    this.stateOptions = options;
    this.addPort(
			new ConstrainedPortModel({
				in: false,
        name: PortType.Gacha,
        nodeTypeConstraint: GachaNodeModel.NODE_TYPE,
        maximumLinks: 1,
        noDuplicateLinks: true
			})
		);
    this.addPort(
			new ConstrainedPortModel({
				in: false,
        name: PortType.Voucher,
        nodeTypeConstraint: VoucherNodeModel.NODE_TYPE,
        maximumLinks: 1,
        noDuplicateLinks: true
			})
		);
  }

  serialize() {
    return {
      ...super.serialize(),
      options: this.stateOptions
    }
  }

  deserialize(event: any): void {
    super.deserialize(event);
    this.stateOptions = event.data.options;
  }

  getStateId(): string {
    return this.stateOptions.stateId;
  }
}

export interface BalancerNodeWidgetProps extends GenericNodeWidgetProps<BalancerNodeModel> { }

export const BalancerNodeWidget = ({ engine, node }: BalancerNodeWidgetProps) => {
  const theme = useTheme() as Theme;
  const color = theme.colors.nodes[NodeType.Balancer].primary;

  useNodeStateListeners(node, engine, node.getStateId());

  const stateId = node.getStateId();
  const live_props = useStore(state => state.diagram.nodes[stateId]);
  let chance_total = 0;
  Object.values(live_props.props.items ?? {}).forEach(item => {
    chance_total += item.chance;
  });
  
  const children = (<NodeChildList>
    {Object.entries(live_props.props.items ?? {}).map(item => (
      <NodeChildItem key={item[0]}>
        <BalancerNameLabel>{normalize_key(item[0])}</BalancerNameLabel>
        <span style={{fontFamily: "monospace", float: "right"}}>
          {Math.round((item[1].chance/chance_total)*100)}%&nbsp;
          {item[1].perm & PERM_COPY ? 'C' : '\u00a0'}
          {item[1].perm & PERM_MODIFY ? 'M' : '\u00a0'}
          {item[1].perm & PERM_TRANSFER ? 'T' : '\u00a0'}
        </span>
      </NodeChildItem>
    ))}
  </NodeChildList>);
  
  return (
    <>
      <NodeWidget selected={node.isSelected()} color={color} icon="balancer-node" node={node} engine={engine} children={children} />
    </>
  );
}

export class BalancerNodeFactory extends AbstractReactFactory<BalancerNodeModel, DiagramEngine> {
  constructor() {
    super(BalancerNodeModel.NODE_TYPE);
  }

  generateModel({ initialConfig }: any) {
    return new BalancerNodeModel({
      stateId: initialConfig.stateId
    });
  }

  generateReactWidget(event: GenerateWidgetEvent<BalancerNodeModel>) {
    return (
      <BalancerNodeWidget engine={this.engine as DiagramEngine} node={event.model} />
    );
  }
}

const BalancerNameLabel = styled.span`
  display: inline-block;
  width: 9.0em;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;