import React from 'react';
import styled, { useTheme } from 'styled-components';
import { AbstractReactFactory, GenerateWidgetEvent } from '@gacha/gacha-diagrams/canvas';
import { DiagramEngine } from '@gacha/gacha-diagrams';
import { PortType, GenericNodeWidgetProps, NodeType } from '../util';
import NodeWidget from '../NodeWidget';
import { Theme } from '../../theme';
import { ConstrainedPortModel } from '../ports';
import { BalancerNodeModel } from './balancer';
import { DistNodeModel } from './distributor';
import { PERM_COPY, PERM_MODIFY, PERM_TRANSFER, StateModelOptions } from '.';
import { useNodeStateListeners } from '../util/hooks';
import { StateNodeModel } from '../StateNodeModel';
import { useStore } from '../../store';
import { NodeChildItem, NodeChildList } from '../styles';
import { normalize_key } from '../../util';

export interface GachaNodeModelOptions extends StateModelOptions { }

export class GachaNodeModel extends StateNodeModel {
  static NODE_TYPE = 'gacha-node';
  stateOptions: GachaNodeModelOptions;

  constructor(options: GachaNodeModelOptions) {
    super({
      ...options,
      type: GachaNodeModel.NODE_TYPE
    });
    this.stateOptions = options;

    this.addPort(
			new ConstrainedPortModel({
				in: true,
        name: PortType.Balancer,
        nodeTypeConstraint: BalancerNodeModel.NODE_TYPE,
        noDuplicateLinks: true
			})
		);
		this.addPort(
			new ConstrainedPortModel({
				in: true,
        name: PortType.Distributor,
        nodeTypeConstraint: DistNodeModel.NODE_TYPE,
        maximumLinks: 1,
        noDuplicateLinks: true
			})
    );
  }

  serialize() {
    return {
      ...super.serialize(),
      stateOptions: this.stateOptions
    }
  }

  deserialize(event: any): void {
    super.deserialize(event);
    this.stateOptions = event.data.options;
  }
}

export interface GachaNodeWidgetProps extends GenericNodeWidgetProps<GachaNodeModel> { }

export const GachaNodeWidget = ({ engine, node }: GachaNodeWidgetProps) => {
  const theme = useTheme() as Theme;
  const color = theme.colors.nodes[NodeType.Gacha].primary;

  useNodeStateListeners(node, engine, node.getStateId());

  const stateId = node.getStateId();
  const live_props = useStore(state => state.diagram.nodes[stateId]);
  let chance_total = 0;
  Object.values(live_props.props.items ?? {}).forEach(item => {
    chance_total += item.chance;
  });

  const children = (<NodeChildList>
    {Object.entries(live_props.props.items ?? {}).map(item => (
      <NodeChildItem key={item[0]}>
        <GachaNameLabel>{normalize_key(item[0])}</GachaNameLabel>
        <span style={{fontFamily: "monospace", float: "right"}}>
          {Math.round((item[1].chance/chance_total)*100)}%&nbsp;
          {item[1].perm & PERM_COPY ? 'C' : '\u00a0'}
          {item[1].perm & PERM_MODIFY ? 'M' : '\u00a0'}
          {item[1].perm & PERM_TRANSFER ? 'T' : '\u00a0'}
        </span>
      </NodeChildItem>
    ))}
  </NodeChildList>);

  return (
    <>
      <NodeWidget selected={node.isSelected()} color={color} icon="gacha-node" node={node} engine={engine} children={children} />
    </>
  );
}

export class GachaNodeFactory extends AbstractReactFactory<GachaNodeModel, DiagramEngine> {
  constructor() {
    super(GachaNodeModel.NODE_TYPE);
  }

  generateModel({ initialConfig }: { initialConfig: any }) {
    return new GachaNodeModel({
      stateId: initialConfig.stateId
    } as any);
  }

  generateReactWidget(event: GenerateWidgetEvent<GachaNodeModel>) {
    return (
      <GachaNodeWidget engine={this.engine as DiagramEngine} node={event.model} />
    );
  }
}

const GachaNameLabel = styled.span`
  display: inline-block;
  width: 9.0em;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;