import { Button, Checkbox } from "antd"
import { AxiosError } from "axios";
import React, { useState } from "react"
import { useHistory } from "react-router-dom";
import { BalancerNodeModel, FLAG_DISABLED, FLAG_OWNERONLY } from "../../diagramming/nodes";
import { useActions, useStore } from "../../store";
import { sanitize_key } from "../../util";
import { PushRedelModal } from "./pushredelmodal";
import { VendSetModal } from "./vendsetmodal";

export const VendorContextMenu = () => {
  const history = useHistory();
  const toggleDisabled = useActions(state => state.api.toggleDisabled);
  const toggleOwnerOnly = useActions(state => state.api.toggleOwnerOnly);
  const vendset = useActions(state => state.api.vendset);
  const pushRedelivery = useActions(state => state.api.pushRedelivery);
  const jwt = useStore(state => state.api.jwt);
  const model = useStore(state => state.diagram.model);
  const nodes = useStore(state => state.diagram.nodes);
  const selection = useStore(state => state.diagram.selection);

  const [vendVisible, setVendVisible] = useState(false);
  const [confirmVendLoading, setVendConfirmLoading] = useState(false);
  const [vendError, setVendError] = useState<string | undefined>(undefined);

  const [redelVisible, setRedelVisible] = useState(false);
  const [confirmRedelLoading, setConfirmRedelLoading] = useState(false);
  const [redelError, setRedelError] = useState<string | undefined>(undefined);
  
  // These two are kind of liars and mostly just used to rerender
  const [ooChecked, setOwnerOnlyChecked] = useState(false);
  const [dsbChecked, setDisabledChecked] = useState(false);

  const nodeFlags = nodes[selection[0].id].props.flags;
  let sdrDisabled = false;
  
  if(selection.length === 0) {
    return (<></>);
  }
  
  const timedOut = (Date.now() / 1000 - selection[0].props.seen) >= 90;

  const routeChange = (route: string) =>{ 
    history.push(route);
  };

  const lockDiagram = () => {
    model.setLocked(true);
  };

  const unlockDiagram = () => {
    model.setLocked(false);
  };

  const onOoChanged = () => {
    const unit = nodes[selection[0].id];
    if(!unit.props.flags) return;
    unit.props.flags ^= FLAG_OWNERONLY;
    setOwnerOnlyChecked(!ooChecked);
    toggleOwnerOnly({
        jwt,
        id: selection[0].id,
        enabled: (unit.props.flags & FLAG_OWNERONLY) === FLAG_OWNERONLY
    }).catch((error: AxiosError) => {
      if(!unit.props.flags) return;
      unit.props.flags ^= FLAG_OWNERONLY;
      setOwnerOnlyChecked(!ooChecked);
    });
  };

  const onDsbChanged = () => {
    const unit = nodes[selection[0].id];
    if(!unit.props.flags) return;
    unit.props.flags ^= FLAG_DISABLED;
    setDisabledChecked(!dsbChecked);
    toggleDisabled({
      jwt,
      id: selection[0].id,
      enabled: (unit.props.flags & FLAG_DISABLED) === FLAG_DISABLED
    }).catch((_error: AxiosError) => {
      if(!unit.props.flags) return;
      unit.props.flags ^= FLAG_DISABLED;
      setDisabledChecked(!dsbChecked);
    });
  };

  const showRedelModal = () => {
    setRedelVisible(true);
    lockDiagram();
  };

  const onRedelCancel = () => {
    setRedelVisible(false);
    unlockDiagram();
    setRedelError(undefined);
    setConfirmRedelLoading(false);
  };

  const onRedelOk = (values: { unit: string, oldItem: string, newItem: string, fnc: boolean }) => {
    setConfirmRedelLoading(true);
    setRedelError(undefined);
    
    pushRedelivery({jwt, unit: values.unit, oldItem: values.oldItem, newItem: values.newItem, fnc: values.fnc }).then((_response: number) => {
      setRedelVisible(false);
      setConfirmRedelLoading(false);
      model.setLocked(false);
    }).catch((error: AxiosError) => {
      setConfirmRedelLoading(false);
      if(error.response) {
        setRedelError(!error.response.data ? "Something went wrong! Please try again." : (error.response.data + "!"))
      } else {
        setRedelError("Something went wrong! Please try again.");
      }
    });
  };

  const showVendModal = () => {
    setVendVisible(true);
    lockDiagram();
  };

  const onVendCancel = () => {
    setVendVisible(false);
    unlockDiagram();
    setVendError(undefined);
    setVendConfirmLoading(false);
  };

  const onVendOk = (values: { [s: string]: { tex?: string, price?: number }; }) => {
    setVendConfirmLoading(true);
    setVendError(undefined);

    vendset({jwt, unit: selection[0].id, settings: values}).then((_response: number) => {
      if(selection[0].props.items !== undefined) {
        for(const item of Object.entries(values)) {
          const k = sanitize_key(item[0]);
          selection[0].props.items[k]["price"] = item[1].price;
          selection[0].props.items[k]["tex"] = item[1].tex;
        }
      }
      setVendVisible(false);
      setVendConfirmLoading(false);
      model.setLocked(false);
    }).catch((error: AxiosError) => {
      setVendConfirmLoading(false);
      if(error.response) {
        setVendError(!error.response.data ? "Something went wrong! Please try again." : (error.response.data + "!"))
      } else {
        setVendError("Something went wrong! Please try again.");
      }
    });
  };

  if(selection[0].props.items !== undefined) {
    const entries = Object.entries(selection[0].props.items);
    sdrDisabled = selection[0].links.findIndex(l => l.to ? nodes[l.to].type === BalancerNodeModel.NODE_TYPE : false) >= 0 ? true : entries.length === 0;
    
  } else {
    sdrDisabled = true;
  }
  
  // TODO item prop modal
  return (
    <>
      <VendSetModal
        visible={vendVisible}
        confirmLoading={confirmVendLoading}
        error={vendError}
        onOk={onVendOk}
        onCancel={onVendCancel}
      />
      <PushRedelModal
        visible={redelVisible}
        confirmLoading={confirmRedelLoading}
        error={redelError}
        onOk={onRedelOk}
        onCancel={onRedelCancel}
      />

      <h1>General Properties</h1>
      <div className="property"><div className="label">Region:</div><div className="value">{selection[0].props.region}</div></div>
      <div className="property">
        <div className="label">Owner Only:</div>
        <div className="value"><Checkbox disabled={timedOut} checked={nodeFlags ? (nodeFlags & FLAG_OWNERONLY) === FLAG_OWNERONLY : false} onChange={onOoChanged} /></div>
      </div>
      <div className="property">
        <div className="label">Disabled:</div>
        <div className="value"><Checkbox disabled={timedOut} checked={nodeFlags ? (nodeFlags & FLAG_DISABLED) === FLAG_DISABLED : false} onChange={onDsbChanged} /></div>
      </div>
      
      <h1>Item Properties</h1>
      <Button
        type="primary"
        block
        onClick={showVendModal}
        disabled={sdrDisabled}>
        Vendor Settings...
      </Button>
      <h1>Analytics Properties</h1>
      <Button type="primary" block onClick={() => routeChange(`/analytics?id=${selection[0].id}`)}>
        View Analytics...
      </Button>

      <h1>Maintenance</h1>
      <Button type="primary" block onClick={showRedelModal}
        disabled={sdrDisabled && selection[0].links.length === 0}>
        Push Redelivery...
      </Button>
    </>
  )
}