import React, { useContext } from 'react';
import { createStore, createTypedHooks, StoreProvider } from 'easy-peasy';
import { createDiagramModel } from './models/diagram';
import ApiClient from './http/ApiClient';
import { composeWithDevTools } from 'redux-devtools-extension';
import createEditorModel from './models/editor';
import createApiModel from './models/api';

const storeObj = {
  diagram: createDiagramModel(),
  editor: createEditorModel(),
  api: createApiModel()
};

// create our own typed hooks and serve them thru context so we don't need to provide type info every time we need to use a hook.
const typedHooks = createTypedHooks<typeof storeObj>();
const contextValue = {
  useActions: typedHooks.useStoreActions,
  useDispatch: typedHooks.useStoreDispatch,
  useStore: typedHooks.useStoreState
};
const StoreContext = React.createContext(contextValue);

// give the HTTP client to thunks via dependency injection for unit testing
const injections = { gachaApi: new ApiClient() }
export type Injections = typeof injections;

const store = createStore<typeof storeObj>(storeObj, { 
  injections, 
  compose: composeWithDevTools({
    stateSanitizer: state => ({
      ...state,
      diagram: {
        // @ts-ignore
        ...state.diagram,
        engine: '<<ENGINE>>',
        model: '<<MODEL>>'
      }
    })
  })
});

export const useStoreContext = () => useContext(StoreContext);
export const StoreContextProvider: React.FC<any> = ({ children, ...props }) => (
  <StoreProvider store={store}>
    <StoreContext.Provider value={contextValue} {...props}>
      {children}
    </StoreContext.Provider>
  </StoreProvider>
);

export const useStore: typeof typedHooks.useStoreState = (...args) => {
  const { useStore: fn } = useStoreContext();
  return fn(...args);
}

export const useActions: typeof typedHooks.useStoreActions = (...args) => {
  const { useActions: fn } = useStoreContext();
  return fn(...args);
}

export const useDispatch: typeof typedHooks.useStoreDispatch = (...args) => {
  const { useDispatch: fn } = useStoreContext();
  return fn(...args);
}