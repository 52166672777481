import { DiagramEngine } from "@gacha/gacha-diagrams";
import { IconNames } from "../components/Icon";
import { StateNodeModel } from "./StateNodeModel";

export interface GenericNodeWidgetProps<TNode extends StateNodeModel> {
  node: TNode;
  engine: DiagramEngine;
}

export interface NodeWidgetProps<TNode extends StateNodeModel> extends GenericNodeWidgetProps<TNode> {
  icon: IconNames;
  color: string;
  children?: React.ReactNode;
  selected?: boolean;
}

export enum NodeType {
  Distributor='distributor',
  Gacha='gacha',
  Balancer='balancer',
  Voucher='voucher',
  Vendor='vendor'
}

export enum PortType {
  Distributor='distributor',
  Gacha='gacha',
  Balancer='balancer',
  Voucher='voucher'
}

type PortsDisplayInfo = {
  [key in PortType]: {
    name: string;
    icon: IconNames;
  }
}
export const PortsDisplayInfo: PortsDisplayInfo = {
  [PortType.Distributor]: {
    name: 'Distributor',
    icon: 'distributor-node'
  },
  [PortType.Gacha]: {
    name: 'Vendor',
    icon: 'gacha-node'
  },
  [PortType.Balancer]: {
    name: 'Balancers',
    icon: 'balancer-node'
  },
  [PortType.Voucher]: {
    name: 'Vouchers',
    icon: 'voucher-node'
  }
};

/**
 * This will return -1 if there is no valid match case. Fill in ALL matches!
 * @param type Type string of node
 */
export function nodeTypeToNum(type: string) {
  switch(type) {
    case 'gacha-node':
      return 0;
    case 'distributor-node':
      return 1;
    case 'balancer-node':
      return 2;
    case 'voucher-node':
      return 3;
    case 'vendor-node':
      return 4;
    default:
      return -1;
  }
}