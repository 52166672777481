import { CaretDownOutlined, CaretRightOutlined, CaretUpOutlined } from "@ant-design/icons";
import { Card, Statistic } from "antd";
import React from "react";
import styled from "styled-components";
import { FlexBreak, PieData, Transaction } from "../AnalyticsView";
import { MultiAreaCard } from "../MultiAreaCard";
import { MultiPieCard } from "../MultiPieCard";

export interface MainProps {
    nameReduced: ({cnt?: number, earn?: number} & Transaction)[],
    regionReduced: ({cnt?: number, earn?: number} & Transaction)[],
    timeReduced: {Plays: number, Date: string, time: number, Earnings: number}[]
}

export const MainDash = ({ nameReduced, regionReduced, timeReduced } : MainProps) => {
    const yesterday = timeReduced.length > 1 ? timeReduced[timeReduced.length-2] : { Plays: 0, Date: '', time: 0, Earnings: 0};
    const today = timeReduced.length > 0 ? timeReduced[timeReduced.length-1] : { Plays: 0, Date: '', time: 0, Earnings: 0};
    const playIncrease = today.Plays-yesterday.Plays;
    const earnIncrease = today.Earnings-yesterday.Earnings;
    return (
        <>
        <Card style={{width: "49%", minWidth: "250px", flex: "0 1 49%", margin: "20px 0", gridRow: "1/3"}}>
            <Statistic 
                title="Total Plays Today"
                value={Math.abs(playIncrease)}
                precision={0}
                valueStyle={{ color: playIncrease === 0 ? 'rgba(0, 0, 0, 0.6)' : playIncrease > 0 ? '#3f8600' : '#cf1322' }}
                prefix={(<><BlackText>{timeReduced[timeReduced.length-1].Plays}</BlackText>&emsp;{playIncrease === 0 ? <CaretRightOutlined /> : playIncrease > 0 ? <CaretUpOutlined /> : <CaretDownOutlined />}</>)}
            />
        </Card>
        <Card style={{width: "49%", minWidth: "250px", flex: "0 1 49%", margin: "20px 0", gridRow: "1/3"}}>
            <Statistic 
                title="Total Earnings Today"
                value={Math.abs(earnIncrease)}
                precision={0}
                valueStyle={{ color: earnIncrease === 0 ? 'rgba(0, 0, 0, 0.6)' : earnIncrease > 0 ? '#3f8600' : '#cf1322' }}
                prefix={(<><BlackText>L$ {timeReduced[timeReduced.length-1].Earnings}</BlackText>&emsp;{earnIncrease === 0 ? (<><CaretRightOutlined /> L$</>) : earnIncrease > 0 ? (<><CaretUpOutlined /> L$</>) : (<><CaretDownOutlined /> L$</>)}</>)}
            />
        </Card>
        <FlexBreak />
        <MultiPieCard
            style={{width: "49%", minWidth: "450px", flex: "0 1 49%", margin: "20px 0", gridRow: "2/3"}}
            titles={["Plays By Machine Name", "Earnings By Machine Name"]}
            types={["Plays", "Earnings"]}
            datas={[
              nameReduced.map(t => {
                return { x: t.unit.name, y: t.cnt };
              }).filter((t): t is PieData => Boolean(t)).sort((a, b) => b.y - a.y),
              nameReduced.map(t => {
                if(t.earn && t.earn > 0)
                  return { x: t.unit.name, y: t.earn };
                else
                  return undefined;
              }).filter((t): t is PieData => Boolean(t)).sort((a, b) => b.y - a.y)
            ]}
          />
          <MultiPieCard
            style={{width: "49%", minWidth: "450px", flex: "0 1 49%", margin: "20px 0", gridRow: "2/3"}}
            titles={["Plays By Region", "Earnings By Region"]}
            types={["Plays", "Earnings"]}
            datas={[
              regionReduced.map(t => {
                if(t.region) {
                  return { x: t.region, y: t.cnt };
                } else
                  return undefined;
              }).filter((t): t is PieData => Boolean(t)).sort((a, b) => b.y - a.y),
              regionReduced.map(t => {
                if(t.region && t.earn && t.earn > 0) {
                  return { x: t.region, y: t.earn };
                } else
                  return undefined;
              }).filter((t): t is PieData => Boolean(t)).sort((a, b) => b.y - a.y)
            ]}
          />
          <FlexBreak />
          <MultiAreaCard
            style={{width: "100%", margin: "20px 0", gridRow: "3/3"}}
            titles={["Plays Per Day", "Earnings Per Day"]}
            types={["Plays", "Earnings"]}
            datas={[
              timeReduced,
              timeReduced
            ]}
            xFields={["Date", "Date"]}
            yFields={["Plays", "Earnings"]}
            sliders={[
              {
                start: 0,
                end: 1,
                trendCfg: { isArea: true, data: [] }
              },
              {
                start: 0,
                end: 1,
                trendCfg: { isArea: true, data: [] }
              }
            ]}
          />
        </>
    );
};

const BlackText = styled.span`
    color: rgba(0, 0, 0, 0.85);
`;