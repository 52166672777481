import React from 'react';
import { useTheme } from 'styled-components';
import { AbstractReactFactory, GenerateWidgetEvent } from '@gacha/gacha-diagrams/canvas';
import { DiagramEngine, } from '@gacha/gacha-diagrams';
import { PortType, GenericNodeWidgetProps, NodeType } from '../util';
import NodeWidget from '../NodeWidget';
import { Theme } from '../../theme';
import { ConstrainedPortModel } from '../ports';
import { BalancerNodeModel } from './balancer';
import { DistNodeModel } from './distributor';
import { StateModelOptions } from '.';
import { useNodeStateListeners } from '../util/hooks';
import { StateNodeModel } from '../StateNodeModel';
import { useStore } from '../../store';
import { NodeChildItem, NodeChildList } from '../styles';

export interface VoucherNodeModelOptions extends StateModelOptions { }

export class VoucherNodeModel extends StateNodeModel {
  static NODE_TYPE = 'voucher-node';
  stateOptions: VoucherNodeModelOptions;

  constructor(options: VoucherNodeModelOptions) {
    super({
      ...options,
      type: VoucherNodeModel.NODE_TYPE
    });
    this.stateOptions = options;

    this.addPort(
			new ConstrainedPortModel({
				in: true,
        name: PortType.Balancer,
        nodeTypeConstraint: BalancerNodeModel.NODE_TYPE,
        noDuplicateLinks: true
			})
		);
		this.addPort(
			new ConstrainedPortModel({
				in: true,
        name: PortType.Distributor,
        nodeTypeConstraint: DistNodeModel.NODE_TYPE,
        maximumLinks: 1,
        noDuplicateLinks: true
			})
    );
  }

  serialize() {
    return {
      ...super.serialize(),
      options: this.stateOptions
    }
  }

  deserialize(event: any): void {
    super.deserialize(event);
    this.stateOptions = event.data.options;
  }

  getStateId(): string {
    return this.stateOptions.stateId;
  }
}

export interface VoucherNodeWidgetProps extends GenericNodeWidgetProps<VoucherNodeModel> { }

export const VoucherNodeWidget = ({ engine, node }: VoucherNodeWidgetProps) => {
  const theme = useTheme() as Theme;
  const color = theme.colors.nodes[NodeType.Voucher].primary;

  useNodeStateListeners(node, engine, node.getStateId());

  const stateId = node.getStateId();
  const item = useStore(state => state.diagram.nodes[stateId].props.item);
  const children = (<NodeChildList>
      <NodeChildItem key={item}>{item === "" ? "{Random Roll}" : item}</NodeChildItem>
  </NodeChildList>);

  return (
    <>
      <NodeWidget selected={node.isSelected()} color={color} icon="voucher-node" node={node} engine={engine} children={children} />
    </>
  );
}

export class VoucherNodeFactory extends AbstractReactFactory<VoucherNodeModel, DiagramEngine> {
  constructor() {
    super(VoucherNodeModel.NODE_TYPE);
  }

  generateModel({ initialConfig }: { initialConfig: any }) {
    return new VoucherNodeModel({
      stateId: initialConfig.stateId
    } as any);
  }

  generateReactWidget(event: GenerateWidgetEvent<VoucherNodeModel>) {
    return (
      <VoucherNodeWidget engine={this.engine as DiagramEngine} node={event.model} />
    );
  }
}