import { Button, Card } from "antd";
import React, { useState } from "react";
import { LabeledPie } from "./LabeledPie";

export interface MultiPieProps {
  datas: [{x: string, y: number}[], {x: string, y: number}[]],
  width?: number,
  height?: number,
  loading?: boolean,
  titles?: [string, string],
  types: [string, string],
  style?: React.CSSProperties
}

/**
 * Mostly just a wrapper around some stuff I have to do to make things look nice.
 * Non-basic usage might have to branch off
 */
export const MultiPieCard = ({ datas, width, height, loading, types, titles, style } : MultiPieProps) => {
  const [type, setType] = useState(true);

  const togType = () => {
    setType(!type);
  };

  return (
    type ?

      <Card title={titles ? titles[0] : undefined} style={style} extra={(
        <Button type="link" onClick={togType}>{types[1]}</Button>
      )}>
        <LabeledPie width={width} height={height} loading={datas[0].length === 0 || loading}
          data={datas[0]}
        />
      </Card>

      :

      <Card title={titles ? titles[1] : undefined} style={style} extra={(
        <Button type="link" onClick={togType}>{types[0]}</Button>
      )}>
        <LabeledPie width={width} height={height} loading={datas[1].length === 0 || loading}
          data={datas[1]}
        />
      </Card>
  );
};