import { DeleteFilled, EditFilled } from "@ant-design/icons";
import { Alert, Button, Col, Divider, Form, InputNumber, Modal, Row, Select, Slider } from "antd"
import { LabeledValue } from "antd/lib/tree-select";
import { AxiosError } from "axios";
import React, { useState } from "react"
import { useActions, useStore } from "../../store";
import { parseJwt } from "../../util";
import { KeysModal } from "./keysmodal";
const { Option } = Select;
const ALPHABETICAL_LAST = "zzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzz";

type SharesModalProps = {
  visible: boolean,
  confirmLoading: boolean,
  error: string | undefined,
  onOk: (values: { _id: string, share: number }[]) => void,
  onCancel: () => void
}

export const SharesModal = ({ visible, confirmLoading, error, onOk, onCancel }: SharesModalProps) => {
  const jwt = useStore(state => state.api.jwt);
  const djwt = parseJwt(jwt);
  const getAllUuidAlias = useActions(state => state.api.getAllUuidAlias);
  const selection = useStore(state => state.diagram.selection);
  const [values, setValues] = useState<{ _id: string, alias: string, share: number }[]>([]);
  const [initItems, setInitItems] = useState(0);
  const [aliases, setAliases] = useState<{ [s: string]: string } | undefined>(undefined);
  const [errorMsg, setErrorMsg] = useState(error);
  const [aliasVisible, setVisible] = useState(false);
  const [aliasDisabled, setAliasDisabled] = useState(true);

  if(!visible) {
    if(initItems !== 0)
      setInitItems(0);
    return (<></>);
  }

  const onChange = (i: number, value: number) => {
    if(isNaN(value)) value = 0;
    let updated = [...values];
    updated[i].share = value;
    let remaining = 1000;

    updated.forEach(item => {
      remaining = remaining - Math.floor(item.share);
    });

    if(remaining < 0) {
      updated[i].share = value + remaining;
    }

    setValues(updated);
  };

  const zeroValues = () => {
    let updated = [...values];

    updated.forEach(item => {
      item.share = 0;
    });

    setValues(updated);
  };

  const onSelect = (value: string | number | LabeledValue, option: any) => {
    if(typeof value !== "string") return;
    let updated = [...values];
    updated.push({_id: value, alias: aliases ? aliases[value] : "", share: 0});
    setValues(updated);
  };

  const formatSliderTip = (value?: number | undefined) => {
    if(!value || isNaN(value)) return "0%";
    return `${value/10}%`;
  }

  const formatSliderInput = (value: string | number | undefined) => {
    if(!value) return "%";
    if(isNaN(+value)) return "0%";
    return `${value}%`;
  }

  if(initItems === 0) {
    if(selection[0].props.shares !== undefined) {
      let tmpValues: any[] = [...selection[0].props.shares];
      tmpValues.find(item => item._id === djwt.uid).alias = djwt.user; // TODO: Max alias 16 lowercase

      setValues(
        tmpValues
      );
    }
    setInitItems(1);
  }

  let remaining = 1000;

  values.forEach(item => {
    remaining = Math.max(remaining - Math.floor(item.share), 0);
  });
  
  if(initItems === 1) {
    if(aliases === undefined) {
      getAllUuidAlias({ jwt })
      .then((data: { [s: string]: string }) => {
        setAliases(data);
        setAliasDisabled(false);
        setValues(values.map(v => {
          if(v.alias) return v;
          return {...v, alias: data[v._id]};
        }));
      })
      .catch((axerr: AxiosError) => {
        setErrorMsg("Failed to retrieve aliases.");
      });
    } else {
      setValues(values.map(v => {
        if(v.alias) return v;
        return {...v, alias: aliases[v._id]};
      }));
    }
    // Handle cases like 33.3333 where 0.1 is left
    if(remaining > 0) {
      values[0].share += remaining;
      remaining = 0;
      setValues(values);
    }
    setInitItems(2);
  }

  const combineErr = errorMsg || error;

  return (
    <>
      <KeysModal
        visible={aliasVisible}
        aliases={aliases ? aliases : {}}
        onOk={(values) => { setVisible(false); setAliases(values); }}
      />
      <Modal
          title="Shares"
          visible={visible}
          onOk={() => onOk(values)}
          confirmLoading={confirmLoading}
          onCancel={onCancel}
          maskClosable={false}
        >
          <Alert className="drop-form-error" style={{display: typeof combineErr === "string" ? "" : "none"}} message={combineErr} type="error" showIcon />
          <Form
          name="basic"
          preserve={false}
          initialValues={values}
        >
          <Row>
          <Col span={22}>
            <Select
              style={{width: "100%"}}
              showSearch
              placeholder="Type to Search"
              optionFilterProp="search"
              onSelect={onSelect}
              filterSort={(optionA: any, optionB: any) =>
                {
                  if(!optionA.search) return 1;
                  if(!optionB.search) return -1;
                  return optionA.search.toLowerCase().localeCompare(optionB.search.toLowerCase());
                }
              }
            >
              {Object.entries(aliases ? aliases : {}).map((item) => (
                <Option disabled={values.findIndex(v => v._id === item[0]) >= 0} key={item[0]} value={item[0]} search={(item[1] === " " ? ALPHABETICAL_LAST : item[1]) + item[0]}>
                  {item[1] !== " " ? (<><b>{item[1]}</b> <small style={{fontFamily: "monospace"}}>{item[0]}</small></>) : (<span style={{fontFamily: "monospace"}}>{item[0]}</span>)} 
                </Option>
              ))}
            </Select>
          </Col>
          <Col span={2}>
            <Button disabled={aliasDisabled} type="primary" shape="circle" size="small" style={{marginLeft: "10px", marginTop: "3.5px"}} onClick={(e) => { setVisible(true); }}>
              <span style={{width: "100%", height: "78%"}}>
                <span style={{position: "absolute", top: "-4px", left: "1.5px", fontSize: "18px"}}><EditFilled /></span>
              </span>
            </Button>
          </Col>
          </Row>
          <div style={{display: "inline-block", fontSize: '12px', marginTop: "6px"}}>
            Remaining: {remaining/10}%
          </div>
          <Button type="primary" size="small" style={{position: "absolute", left: "9em", marginTop: "4px"}} onClick={(e) => zeroValues()}>
            Zero
          </Button>
          <Divider style={{margin: '24px 0'}}/>
          {values.map((item, ind) => (
            <Form.Item key={item._id}>
              <Row style={{marginBottom: "6px"}}>
                <Col span={23}>
                  {item.alias !== " " ? (<><b>{item.alias}</b> <small style={{fontFamily: "monospace"}}>{item._id}</small></>) : (<small style={{fontFamily: "monospace"}}>{item._id}</small>)} 
                </Col>

                {item._id === djwt.uid ? (<></>) : (
                <Col span={1}>
                  <Button type="primary" danger shape="circle" size="small" style={{float: "right"}} onClick={(e) => setValues(values.filter(v => v._id !== item._id))}><DeleteFilled className="deleteBtnIcon" style={{marginLeft: "-1px"}} /></Button>
                </Col>
                )}
              </Row>
              <Row>
                <Col span={19}>
                  <Slider 
                  onChange={(num: number) => onChange(ind, num)}
                  tipFormatter={formatSliderTip} max={1000}
                  value={item.share} />
                </Col>
                <Col span={5}>
                  <InputNumber
                    min={0}
                    max={100}
                    step={0.1}
                    style={{ margin: '0 0 0 16px', width: '4.75rem' }}
                    value={item.share/10}
                    formatter={formatSliderInput}
                    onChange={(num: string | number | undefined) => onChange(ind, Number(num ?? 0)*10)}
                  />
                </Col>
              </Row>
            </Form.Item>
          ))}
        </Form>
      </Modal>
    </>
  )
}