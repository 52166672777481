import React, { useState } from 'react';
import styled from 'styled-components';
import { useActions } from '../../store';
import gridBg from '../../assets/editor_bg.png';
import  { Redirect, useLocation } from 'react-router-dom'
import smLogo from './img/tetra-logo-nt-64.png';
import { Spin, Button, Alert, Card } from 'antd';
import { parseJwt } from '../../util';
import { AxiosError } from 'axios';

interface Item {
  item: string,
  time: number,
  redeliverable: boolean
}

export const RedeliveryView = () => {
  const getRedeliveries = useActions(actions => actions.api.getRedeliveries);
  const redeliverItem = useActions(actions => actions.api.redeliverItem);
  const query = new URLSearchParams(useLocation().search);
  const token = query.get("t");
  const [initialized, setInitialized] = useState(0);
  const [items, setItems] = useState<Item[]>([]);
  const [sending, setSending] = useState<string | null>(null);
  const [alert, setAlert] = useState<{msg: string, type: "success" | "error" | "info" | "warning"} | null>(null);

  if(!token || parseJwt(token).exp < Math.floor(Date.now()/1000)) {
    return (
      <Redirect to='/expired'/>
    );
  }

  const redeliver = (item: string) => {
    if(sending === null) {
      setSending(item);
      redeliverItem({jwt: token, item}).then((msg: string) => {
        const args = msg.split("|");
        if(args.length > 0 && args[0] === "200") {
          setAlert({type: "success", msg: "Your redelivery request has been received."});
        } else {
          setAlert({type: "error", msg: "Something went wrong! Please try again."});
        }
        console.log(msg);
      }).catch((error: AxiosError) => {
        console.log(error);
        if(error.response?.status === 401) {
          setAlert({type: "error", msg: "This session has expired. Please visit redelivery terminal again."});
        } else {
          setAlert({type: "error", msg: "Something went wrong! Please try again."});
        }
      }).finally(() => {
        setTimeout(() => setAlert(null), 6000);
      });
      setTimeout(() => {
        setSending(null);
      }, 3000);
    }
  };

  if(initialized === 0) {
    setInitialized(1);
    getRedeliveries({jwt: token }).then((items: Item[]) => {
      items.sort((a, b) => {
        return b.time - a.time;
      })
      setItems(items);
    }).catch((error: AxiosError) => {
      if(error.response?.status === 401) {
        setAlert({type: "error", msg: "This session has expired. Please visit redelivery terminal again."});
      } else {
        setAlert({type: "error", msg: "Something went wrong! Please try again."});
      }
      setTimeout(() => setAlert(null), 6000);
    }).finally(() => {
      setInitialized(2);
    });
  }
  
  return (
    <>
      <Alert style={{position: "absolute", marginLeft: "30%", top: "10px", zIndex: 1000, display: alert !== null ? "" : "none", width: "40%"}} message={alert ? alert.msg : ""} type={alert ? alert.type : "error"} showIcon />
      <ViewContainer>
        {initialized === 2 ? (items.length > 0 ? items.map(item => (
          <Card key={item.item} title={item.item} className="redel-card">
            <div title={item.item} className="redel-title-hover">
            </div>
            <Button disabled={!item.redeliverable || (sending !== null && sending !== item.item)} loading={sending === item.item} block type="primary" onClick={() => redeliver(item.item)}>Redeliver</Button>
          </Card>
        )) : (
          <BasicContainer>
            Looks like you have no products to redeliver.
            <LogoSmall />
          </BasicContainer>
          ) ) : (<BasicContainer><Spin /><LogoSmall /></BasicContainer>)}
      </ViewContainer>
    </>
  );
};

const ViewContainer = styled.div`
  width: 100%;
  height: 100%;
  background: url('${gridBg}');
  position: relative;
  overflow-y: scroll;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 8px;
`;

const BasicContainer = styled.div`
  width: 450px;
  height: 300px;
  background: #fdfdfd;
  border-radius: 24px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 5px 10px 12px 12px #00000033;
`;

const LogoSmall = styled.div`
  background: url('${smLogo}');
  background-repeat: no-repeat;
  background-size: contain;
  bottom: 24px;
  /*right: 10px;*/
  width: 30px;
  height: 30px;
  position: absolute;
  display: block;
`;

export default RedeliveryView;