import React from 'react';
import styled from 'styled-components';
import { BalancerNodeModel, DistNodeModel, GachaNodeModel, VendorNodeModel } from '../../diagramming/nodes';
import { VoucherNodeModel } from '../../diagramming/nodes/voucher';
import { useActions, useStore } from '../../store';
import { StyledProps } from '../../util/styled-types';
import { BalancerContextMenu } from './balancer';
import { DistContextMenu } from './distributor';
import { GachaContextMenu } from './gacha';
import { VoucherContextMenu } from './voucher';
import OpenCloseToggle from './OpenCloseToggle';
import MenuButton from './MenuButton';
import { FundFilled, ReadOutlined, SolutionOutlined, UserOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { isChrome, parseJwt } from '../../util';
import { Tooltip } from 'react-tippy';
import { VendorContextMenu } from './vendor';

export interface ContextMenuProps {
}

export const ContextMenu = () => {
  const jwt = useStore(state => state.api.jwt);
  const selection = useStore(state => state.diagram.selection);
  const toggleOpen = useActions(actions => actions.editor.contextMenu.toggleOpen);
  const open = useStore(state => state.editor.contextMenu.open);
  const history = useHistory();
  const pjwt = parseJwt(jwt);

  const routeChange = (route: string) =>{ 
    history.push(route);
  }

  let contents = (<></>);

  if(selection.length === 1) {
    if (selection[0].type === GachaNodeModel.NODE_TYPE) {
      contents = (<GachaContextMenu />);
    } else if (selection[0].type === DistNodeModel.NODE_TYPE) {
      contents = (<DistContextMenu />);
    } else if (selection[0].type === BalancerNodeModel.NODE_TYPE) {
      contents = (<BalancerContextMenu />);
    } else if (selection[0].type === VoucherNodeModel.NODE_TYPE) {
      contents = (<VoucherContextMenu />);
    } else if (selection[0].type === VendorNodeModel.NODE_TYPE) {
      contents = (<VendorContextMenu />);
    }
  } else if(selection.length > 1) {
    contents = (<div className="center">Many</div>);
  }
  //
  return (
    <Menu>
      {open && selection.length !== 0 ? (
        <Flex>
          <ContainerHeader>{selection[selection.length-1].name} <small>({selection[selection.length-1].id.substring(selection[selection.length-1].id.length-7).toUpperCase()})</small></ContainerHeader>
          <Container className="sidebar">
            {contents}
          </Container>
        </Flex>
      ) : (<ContainerHeader>&nbsp;</ContainerHeader>)}
      <OutsideMenuContainerTop>
        <MenuButton onClick={() => {}} Icon={UserOutlined} style={{marginBottom: "6px", display: "none"}} />
        <Tooltip offset={isChrome?-20:0} distance={isChrome?-30:10} title="Analytics" position="bottom" theme="light" delay={500}>
          <MenuButton onClick={() => routeChange("/analytics")} Icon={FundFilled} />
        </Tooltip>
      </OutsideMenuContainerTop>
      <OutsideMenuContainerBottom>
        <Tooltip offset={isChrome?-20:0} title="Feedback" position="top" theme="light" delay={500}>
          <MenuButton style={{marginBottom: "6px"}} onClick={() => window.open(`https://docs.google.com/forms/d/e/1FAIpQLSe-aook5wid35uXZEagcLZaXkf31PSFWFstVYKA1OI5SRCXnA/viewform?usp=pp_url&entry.203866297=${pjwt.user}`, "_blank")} Icon={SolutionOutlined} />
        </Tooltip>
        <Tooltip offset={isChrome?-20:0} title="Wiki" position="top" theme="light" delay={500}>
          <MenuButton style={{marginBottom: "6px"}} onClick={() => window.open(`http://wiki.tetra.network/`, "_blank")} Icon={ReadOutlined} />
        </Tooltip>
        <Tooltip offset={isChrome?-20:0} title="Properties" position="top" theme="light" delay={500}>
          <OpenCloseToggle open={open} onClick={toggleOpen} orientation="right" />
        </Tooltip>
      </OutsideMenuContainerBottom>
    </Menu>
  )
}

const Menu = styled.div`
  position: relative;
  border-left: 1px solid ${({ theme }: StyledProps) => theme.colors.menu.border};
  background-color: ${({ theme }: StyledProps) => theme.colors.menu.background};
  color: ${({ theme }: StyledProps) => theme.colors.menu.text};
  height: 100%;
  width: 15rem;
  box-sizing: border-box;
`;

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Container = styled.div`
  overflow-y: auto;
  flex: 1 1 auto;
`;

const ContainerHeader = styled.header`
  padding: 1rem 0.5rem;
	border-bottom: 1px solid rgba(255,255,255,0.25);
  background-color: #303030;
  text-align: center;
`;

const OutsideMenuContainerBottom = styled.div`
  position: absolute;
  left: -4.1rem;
  bottom: 0;
  padding: 1rem;
`;

const OutsideMenuContainerTop = styled.div`
  position: absolute;
  left: -4.1rem;
  top: 0;
  padding: 1rem;
`;

export default ContextMenu;