import React, { useState } from 'react';
import { NodeWidgetProps } from './util';
import { NodeContainer, TitleContainer, NodeIcon, PortContainer, PortIcon, Port, PortCircle, PortCircleContainer, NodePortIconContainer, TitleText, NodeChildren } from './styles';
import { DiagramEngine, PortWidget, PortModel, PortModelGenerics } from '@gacha/gacha-diagrams';
import { PortsDisplayInfo, PortType } from './util';
import { Tooltip } from 'react-tippy';
import { useTheme } from 'styled-components';
import { Theme } from '../theme';
import { useStore } from '../store';
import { StateNodeModel } from './StateNodeModel';

export const NodeWidget = <T extends StateNodeModel>({ engine, color, icon, node, selected, children }: NodeWidgetProps<T>) => {
  const [dragging, setDragging] = useState(false);
  const stateId = node.getStateId();
  const live_props = useStore(state => state.diagram.nodes[stateId]);

  const now = Date.now() / 1000;
  const timedOut = (now - (live_props ? live_props.props.seen : 0)) >= 90;
  const name = live_props ? live_props.name : "undefined";
  return (
    <>
        <NodeContainer title={name + ` (${live_props.id.substring(live_props.id.length-7).toUpperCase()})`} color={color}
            timedOut={timedOut}
            selected={selected}
            onMouseDown={() => setDragging(true)} 
            onMouseUp={() => setDragging(false)}
            dragging={dragging}>
          <TitleContainer>
            <NodeIcon name={icon} />
            <TitleText>{name} <small>({live_props.id.substring(live_props.id.length-7).toUpperCase()})</small></TitleText>
          </TitleContainer>
          <NodeChildren>
            {children}
          </NodeChildren>
        </NodeContainer>
      <PortContainer>
        {Object.entries(node.getPorts())
          .map(([key, port]) => (
            <NodePort port={port} key={key} portType={key as PortType} engine={engine} />
        ))}
      </PortContainer>
    </>
  );
}

export interface NodePortProps {
  engine: DiagramEngine;
  port: PortModel<PortModelGenerics>;
  portType: PortType;
  color?: string;
}

export const NodePort: React.FC<NodePortProps> = ({ engine, port, portType, color, ...props }: NodePortProps) => {
  const theme= useTheme() as Theme;
  if (!color) {
    color = theme.colors.nodes[portType]?.portDot ?? 'cornflowerblue';
  }
  return (
    <Port {...props}>
      <Tooltip title={PortsDisplayInfo[portType].name} position="top" theme="light" delay={250}>
        <NodePortIconContainer>
          <PortIcon name={PortsDisplayInfo[portType].icon} />
        </NodePortIconContainer>
      </Tooltip>
      <PortWidget engine={engine} port={port}>
        <PortCircleContainer>
          <PortCircle color={color} />
        </PortCircleContainer>
      </PortWidget>
    </Port>
  );
}

export default React.memo(NodeWidget);