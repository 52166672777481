import React, { useState } from 'react';
import { DiagramEngine } from '@gacha/gacha-diagrams';
import { AbstractReactFactory, GenerateWidgetEvent } from '@gacha/gacha-diagrams/canvas';
import { GenericNodeWidgetProps, NodeType, PortType } from '../util';
import NodeWidget from '../NodeWidget';
import styled, { useTheme } from 'styled-components';
import { Theme } from '../../theme';
import { ConstrainedPortModel } from '../ports';
import { GachaNodeModel } from './gacha';
import { StateModelOptions } from '.';
import { useNodeStateListeners } from '../util/hooks';
import { VoucherNodeModel } from './voucher';
import { StateNodeModel } from '../StateNodeModel';
import { useActions, useStore } from '../../store';
import { NodeChildItem, NodeChildList } from '../styles';
import { parseJwt } from '../../util';
import { useEffect } from 'react';

export interface DistNodeModelOptions extends StateModelOptions {}

export class DistNodeModel extends StateNodeModel {
  static NODE_TYPE = 'distributor-node';
  stateOptions: DistNodeModelOptions;

  constructor(options: DistNodeModelOptions) {
    super({
      ...options,
      type: DistNodeModel.NODE_TYPE
    });
    this.stateOptions = options;

		this.addPort(
			new ConstrainedPortModel({
				in: false,
        name: PortType.Gacha,
        nodeTypeConstraint: GachaNodeModel.NODE_TYPE,
        noDuplicateLinks: true
			})
		);

		this.addPort(
			new ConstrainedPortModel({
				in: false,
        name: PortType.Voucher,
        nodeTypeConstraint: VoucherNodeModel.NODE_TYPE,
        noDuplicateLinks: true
			})
		);
  }

  serialize() {
    return {
      ...super.serialize(),
      options: this.stateOptions
    }
  }

  deserialize(event: any): void {
    super.deserialize(event);
    this.stateOptions = event.data.options;
  }

  getStateId(): string {
    return this.stateOptions.stateId;
  }
}

export interface DistNodeWidgetProps extends GenericNodeWidgetProps<DistNodeModel> { }

export const DistNodeWidget = ({ engine, node }: DistNodeWidgetProps) => {
  const theme = useTheme() as Theme;
  const color = theme.colors.nodes[NodeType.Distributor].primary;

  useNodeStateListeners(node, engine, node.getStateId());

  const stateId = node.getStateId();
  const shares = useStore(state => state.diagram.nodes[stateId].props.shares);
  const jwt = useStore(state => state.api.jwt);
  const djwt = parseJwt(jwt);
  const getAllUuidAlias = useActions(state => state.api.getAllUuidAlias);
  const [aliases, setAliases] = useState<{ [s: string]: string } | undefined>(undefined);
  useEffect(() => {
    if(!aliases) {
      getAllUuidAlias({ jwt }).then((data: { [s: string]: string }) => {
        setAliases(data);
      });
    }
  });
  
  const children = (<NodeChildList>
    {(shares ?? []).map(share =>
      share._id !== djwt.uid ? (
        <NodeChildItem key={share._id}>
          <DistNameLabel>{aliases ? (aliases[share._id] && aliases[share._id].trim().length > 0 ? aliases[share._id] : share._id) : share._id}</DistNameLabel>
          <span style={{float: "right"}}>{Math.round(share.share/10.0)}%</span>
        </NodeChildItem>
      ) : (<React.Fragment key={share._id}></React.Fragment>)
    )}
  </NodeChildList>);

  return (
    <>
      <NodeWidget selected={node.isSelected()} color={color} icon="distributor-node" node={node} engine={engine} children={children} />
    </>
  );
}

export class DistNodeFactory extends AbstractReactFactory<DistNodeModel, DiagramEngine> {
  constructor() {
    super(DistNodeModel.NODE_TYPE);
  }

  generateModel({ initialConfig }: any) {
    return new DistNodeModel({
      stateId: initialConfig.stateId
    });
  }

  generateReactWidget(event: GenerateWidgetEvent<DistNodeModel>) {
    return (
      <DistNodeWidget engine={this.engine as DiagramEngine} node={event.model} />
    );
  }
}

const DistNameLabel = styled.span`
  display: inline-block;
  width: 11em;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;