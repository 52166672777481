import { Action, Computed, action, computed } from "easy-peasy";
import getConfig from "../config";

export interface EditorModel {
  zoom: {
    value: number,
    percent: Computed<EditorModel['zoom'], number>;
    set: Action<EditorModel['zoom'], number>
  },
  contextMenu: {
    open: boolean;
    toggleOpen: Action<EditorModel['contextMenu'], void>;
    setOpen: Action<EditorModel['contextMenu'], boolean>;
  }
}

export const createEditorModel = () => {
  return {
    zoom: {
      value: getConfig().editor.BACKGROUND_SIZE_PX,
      set: action((state, payload) => {
        state.value = payload
      }),
      percent: computed(state => (state.value / getConfig().editor.BACKGROUND_SIZE_PX))
    },
    contextMenu: {
      open: false,
      toggleOpen: action((state) => {
        state.open = !state.open;
      }),
      setOpen: action((state, payload) => {
        state.open = payload;
      })
    }
  } as EditorModel;
}

export default createEditorModel;