import React from 'react';
import styled from 'styled-components';
import gridBg from '../../assets/editor_bg.png';
import smLogo from './img/tetra-logo-nt-64.png';

export const ExpiredView = () => {
  return (
    <ViewContainer>
      <BasicContainer>
        This session has expired.
      <LogoSmall />
      </BasicContainer>
    </ViewContainer>
  );
};

const ViewContainer = styled.div`
  width: 100%;
  height: 100%;
  background: url('${gridBg}');
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BasicContainer = styled.div`
  width: 450px;
  height: 300px;
  background: #fdfdfd;
  border-radius: 24px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 5px 10px 12px 12px #00000033;
`;

const LogoSmall = styled.div`
  background: url('${smLogo}');
  background-repeat: no-repeat;
  background-size: contain;
  bottom: 24px;
  /*right: 10px;*/
  width: 30px;
  height: 30px;
  position: absolute;
  display: block;
`;

export default ExpiredView;