import { DiagramEngine, LinkModel, PortModel } from "@gacha/gacha-diagrams";
import { BaseEntity } from "@gacha/gacha-diagrams/canvas";
import { useCallback, useEffect, useRef } from "react";
import { DiagramModel } from "../../models/diagram";
import { useActions, useStore } from "../../store";
import { GachaNodeModel, VendorNodeModel } from "../nodes";
import { VoucherNodeModel } from "../nodes/voucher";
import { StateNodeModel } from "../StateNodeModel";
import { nodeTypeToNum, PortType } from "../util";
import { Node } from "../../models/diagram";

type NodeEvent<T extends BaseEntity> = {
  function: 'positionChanged';
  entity: T;
} | {
  function: 'selectionChanged';
  isSelected: boolean;
  entity: T;
};

type ModelEvent<T extends BaseEntity> = {
  function: 'nodesUpdated';
  isCreated: boolean;
  node: T;
} | {
  function: 'linksUpdated';
  isCreated: boolean;
  link: LinkModel;
  entity: DiagramModel;
}

type PortEvent = {
  function: 'reportInitialPosition';
  entity: PortModel;
}

export function useNodeStateListeners<T extends StateNodeModel>(node: T, engine: DiagramEngine, stateId: string) {
  const nodes = useStore(store => store.diagram.nodes);
  const currentLink = useStore(store => store.diagram.currentLink);
  const updateSelection = useActions(actions => actions.diagram.updateSelection);
  const updateNode = useActions(actions => actions.diagram.updateNode);
  const jwt = useStore(state => state.api.jwt);
  const attach = useActions(actions => actions.api.attach);
  const setCurrentLink = useActions(actions => actions.diagram.setCurrentLink);
  const positionDebouncer = useRef<number>();
  const applyLink = useActions(actions => actions.diagram.applyLink);
  const savePos = useActions(actions => actions.api.savePos);

  const getStateNodeFromViewId = useCallback((viewId: string) => {
    return viewId ? Object.values(nodes).find(node => node.viewId === viewId) : null;
  }, [nodes]);

  useEffect(() => {
    const handle = node.registerListener({
      eventDidFire: (e: NodeEvent<T>) => {
        if (e.function === 'selectionChanged' && e.entity === node) {
          updateSelection({ selected: e.isSelected, id: stateId })
        } else if (e.function === 'positionChanged' && e.entity === node) {
          if (positionDebouncer.current !== undefined) {
            clearTimeout(positionDebouncer.current);
          }
          positionDebouncer.current = setTimeout(() => {
            const node = nodes[stateId];
            if(node && node.x !== e.entity.getX() && node.y !== e.entity.getY()) {
              console.log(`Saving ${stateId}...`);
              savePos({ jwt, id: stateId, pos: { x: e.entity.getX(), y: e.entity.getY() } });
            }
            updateNode({ id: stateId, x: e.entity.getX(), y: e.entity.getY() });
          }, 150);
        }
      }
    } as any);
    return () => void node.deregisterListener(handle);
  }, [updateSelection, updateNode, node, stateId, jwt, nodes, savePos]);

  useEffect(() => {
    const unsub = Object.values(node.getPorts())
      .map(port => {
        const handle = port.registerListener({
          eventDidFire: (e: PortEvent) => {
            if (e.function === 'reportInitialPosition') {
              Object.entries(e.entity.getLinks())
                .forEach(([key, link]) => {
                  if (link.getID() !== currentLink?.viewId) {
                    return;
                  }
                  const targetNode = getStateNodeFromViewId(link.getTargetPort()?.getParent()?.getID());
                  if (!targetNode) {
                    return;
                  }

                  let payload = {jwt, gachaOrVoucher: "", other: "", otherType: 0};

                  if(targetNode.type === GachaNodeModel.NODE_TYPE || targetNode.type === VoucherNodeModel.NODE_TYPE || targetNode.type === VendorNodeModel.NODE_TYPE) {
                    payload.gachaOrVoucher = targetNode.id;
                    payload.other = currentLink.fromId;
                    payload.otherType = nodeTypeToNum(currentLink.fromType);
                  } else {
                    payload.gachaOrVoucher = currentLink.fromId;
                    payload.other = targetNode.id;
                    payload.otherType = nodeTypeToNum(targetNode.type);
                  }
                  
                  console.log("Attaching...")
                  attach(payload).then((successType: number) => {
                    if(successType !== 0) {
                      console.log("Attached "+currentLink.fromId+" to "+targetNode.id);
                      applyLink({
                        from: currentLink.fromId,
                        to: targetNode.id,
                        viewId: currentLink.viewId
                      });
                    } else {
                      console.log("Failed to attach (0)");
                      link.setSelected(false);
                      link.remove();
                      engine.repaintCanvas();
                    }
                  }).catch((err: any) => {
                    console.log("Failed to attach (1)");
                    link.setSelected(false);
                    link.remove();
                    engine.repaintCanvas();
                  }).finally(() => {
                    setCurrentLink(null);
                  });
                });
            }
          }
        } as any);
        return () => port.deregisterListener(handle);
      });

    return () => unsub.forEach(fn => fn());
  });
}

export function useEngineModelStateListeners(engine: DiagramEngine) {
  const nodes = useStore(store => store.diagram.nodes);
  const deleteNode = useActions(actions => actions.diagram.deleteNode);
  const setCurrentLink = useActions(actions => actions.diagram.setCurrentLink);
  const deleteLink = useActions(actions => actions.diagram.deleteLink);
  const createLink = useActions(actions => actions.diagram.createLink);
  const jwt = useStore(state => state.api.jwt);
  const detach = useActions(actions => actions.api.detach);
  const deleteUnit = useActions(actions => actions.api.deleteUnit);

  const getStateNodeFromViewId = useCallback((viewId: string) => {
    return viewId ? Object.values(nodes).find(node => node.viewId === viewId) : null;
  }, [nodes]);

  useEffect(() => {
    const handle = engine.getModel().registerListener({
      eventDidFire: (e: ModelEvent<BaseEntity>) => {
        if (e.function === 'nodesUpdated' && !e.isCreated) {
          const state = getStateNodeFromViewId(e.node.getID());
          if (!state) {
            return;
          }

          // TODO: Maybe an "Are you sure?" dialog for active or
          //  voucher nodes. Vouchers will often be inactive but
          //  still ideal to keep. Also, a prune button.
          deleteUnit({jwt, id: state.id}).then((success: number) => {
            deleteNode({ id: state.id });
            // TODO: Find a way to put the node back if it fails.
            //  Right now it's okay because the timer will renew
            //  all of the elements when it ticks. The main
            //  concern is recreating links.
            //  Maybe an event could be fired and deletion happens
            //  here?
          }).catch((err: any) => {
            deleteNode({ id: state.id });
            // See above.
          });
        } else if (e.function === 'linksUpdated') {
          if (e.isCreated) {
            const sourceNode = getStateNodeFromViewId(e.link.getSourcePort().getParent().getID());
            if (!sourceNode) {
              return;
            }
            
            setCurrentLink({
              fromType: sourceNode.type,
              fromId: sourceNode.id,
              viewId: e.link.getID()
            });
          } else {
            const viewId = e.link?.getOptions()?.id;
            let sourceNode: Node | undefined = undefined;
            let targetNode: Node | undefined = undefined;
            // Our prev method of grabbing through the diagram link models was failing us so we do it the hard way
            for(const n of Object.values(nodes)) {
              if(sourceNode) break;
              for(const l of n.links) {
                if(l.viewId === viewId) {
                  if(!l.to) {
                    console.warn("l.to is screwed up");
                    break;
                  } else {
                    sourceNode = nodes[l.from];
                    targetNode = nodes[l.to];
                    break;
                  }
                }
              }
            }

            if (!sourceNode || !targetNode || !viewId) {
              return;
            }
            
            let payload = {jwt, gachaOrVoucher: "", other: "", otherType: 0};
            let fromPortType = PortType.Balancer;
            let toPortType = PortType.Gacha;

            if(targetNode.type === GachaNodeModel.NODE_TYPE || targetNode.type === VoucherNodeModel.NODE_TYPE || targetNode.type === VendorNodeModel.NODE_TYPE) {
              payload.gachaOrVoucher = targetNode.id;
              payload.other = sourceNode.id;
              payload.otherType = nodeTypeToNum(sourceNode.type);
              if(payload.otherType === 1) {
                fromPortType = PortType.Distributor;
              }
              if(targetNode.type === VoucherNodeModel.NODE_TYPE) {
                toPortType = PortType.Voucher;
              }
            } else {
              payload.gachaOrVoucher = sourceNode.id;
              payload.other = targetNode.id;
              payload.otherType = nodeTypeToNum(targetNode.type);
              if(payload.otherType === 1) {
                fromPortType = PortType.Distributor;
              }
              if(sourceNode.type === VoucherNodeModel.NODE_TYPE) {
                toPortType = PortType.Voucher;
              }
            }

            if (payload.gachaOrVoucher === payload.other) {
              console.warn("Something is wrong with detach");
              return;
            }
            
            console.log("Detaching...")
            detach(payload).then((successType: number) => {
              if(successType !== 0) {
                if(sourceNode && targetNode)
                  console.log("Detached "+sourceNode.id+" from "+targetNode.id);
                deleteLink(viewId);
              } else {
                console.log("Detach failed (0)");
                createLink({link: { from: payload.gachaOrVoucher, to: payload.other }, fromPortType, toPortType});
              }
            }).catch((err: any) => {
              console.log("Detach failed (1)");
              createLink({link: { from: payload.gachaOrVoucher, to: payload.other }, fromPortType, toPortType});
            });
          }
        }
        else {
          // console.log(e);
        }
      }
    } as any);

    return () => {
      engine.getModel().deregisterListener(handle);
    }
  }, [deleteNode, engine, nodes, setCurrentLink, getStateNodeFromViewId, deleteLink, createLink, detach, jwt, deleteUnit]);
}