import { Button, Checkbox, InputNumber } from "antd"
import { AxiosError } from "axios";
import React, { useState } from "react"
import { useHistory } from "react-router-dom";
import { BalancerNodeModel, FLAG_DISABLED, FLAG_OWNERONLY } from "../../diagramming/nodes";
import { useActions, useStore } from "../../store";
import { sanitize_key } from "../../util";
import { DropRateModal } from "./dropratemodal";
import { PushRedelModal } from "./pushredelmodal";

export const GachaContextMenu = () => {
  const history = useHistory();
  const toggleDisabled = useActions(state => state.api.toggleDisabled);
  const toggleOwnerOnly = useActions(state => state.api.toggleOwnerOnly);
  const apiSetPrice = useActions(state => state.api.setPrice);
  const droprates = useActions(state => state.api.droprates);
  const pushRedelivery = useActions(state => state.api.pushRedelivery);
  const jwt = useStore(state => state.api.jwt);
  const model = useStore(state => state.diagram.model);
  const nodes = useStore(state => state.diagram.nodes);
  const selection = useStore(state => state.diagram.selection);

  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);

  const [redelVisible, setRedelVisible] = useState(false);
  const [confirmRedelLoading, setConfirmRedelLoading] = useState(false);
  const [redelError, setRedelError] = useState<string | undefined>(undefined);

  const [curPrice, setCurPrice] = useState(-1);
  const [priceLoading, setPriceLoading] = useState(false);
  
  // These two are kind of liars and mostly just used to rerender
  const [ooChecked, setOwnerOnlyChecked] = useState(false);
  const [dsbChecked, setDisabledChecked] = useState(false);

  const nodeFlags = nodes[selection[0].id].props.flags;
  let sdrDisabled = false;
  
  if(selection.length === 0) {
    return (<></>);
  }
  
  const timedOut = (Date.now() / 1000 - selection[0].props.seen) >= 90;

  const routeChange = (route: string) =>{ 
    history.push(route);
  };

  const lockDiagram = () => {
    model.setLocked(true);
  };

  const unlockDiagram = () => {
    model.setLocked(false);
  };

  const setPrice = () => {
    setPriceLoading(true);
    apiSetPrice({jwt, id: selection[0].id, price: curPrice}).then((_response: number) => {
      /*if(response === 1) {
      } else {
        // TODO: Error icon on button?
      }*/
      selection[0].props.price = curPrice;
      setPriceLoading(false);
    }).catch((_error: AxiosError) => {
      setPriceLoading(false);
      // TODO: Error icon on button?
    })
  };

  const onOoChanged = () => {
    const unit = nodes[selection[0].id];
    if(!unit.props.flags) return;
    unit.props.flags ^= FLAG_OWNERONLY;
    setOwnerOnlyChecked(!ooChecked);
    toggleOwnerOnly({
        jwt,
        id: selection[0].id,
        enabled: (unit.props.flags & FLAG_OWNERONLY) === FLAG_OWNERONLY
    }).catch((error: AxiosError) => {
      if(!unit.props.flags) return;
      unit.props.flags ^= FLAG_OWNERONLY;
      setOwnerOnlyChecked(!ooChecked);
    });
  };

  const onDsbChanged = () => {
    const unit = nodes[selection[0].id];
    if(!unit.props.flags) return;
    unit.props.flags ^= FLAG_DISABLED;
    setDisabledChecked(!dsbChecked);
    toggleDisabled({
      jwt,
      id: selection[0].id,
      enabled: (unit.props.flags & FLAG_DISABLED) === FLAG_DISABLED
    }).catch((_error: AxiosError) => {
      if(!unit.props.flags) return;
      unit.props.flags ^= FLAG_DISABLED;
      setDisabledChecked(!dsbChecked);
    });
  };

  const showRedelModal = () => {
    setRedelVisible(true);
    lockDiagram();
  };

  const onRedelCancel = () => {
    setRedelVisible(false);
    unlockDiagram();
    setRedelError(undefined);
    setConfirmRedelLoading(false);
  };

  const onRedelOk = (values: { unit: string, oldItem: string, newItem: string, fnc: boolean }) => {
    setConfirmRedelLoading(true);
    setRedelError(undefined);
    
    pushRedelivery({jwt, unit: values.unit, oldItem: values.oldItem, newItem: values.newItem, fnc: values.fnc }).then((_response: number) => {
      setRedelVisible(false);
      setConfirmRedelLoading(false);
      model.setLocked(false);
    }).catch((error: AxiosError) => {
      setConfirmRedelLoading(false);
      if(error.response) {
        setRedelError(!error.response.data ? "Something went wrong! Please try again." : (error.response.data + "!"))
      } else {
        setRedelError("Something went wrong! Please try again.");
      }
    });
  };

  const showModal = () => {
    setVisible(true);
    lockDiagram();
  };

  const onCancel = () => {
    setVisible(false);
    unlockDiagram();
    setError(undefined);
    setConfirmLoading(false);
  };

  const onOk = (values: { [s: string]: number; }) => {
    setConfirmLoading(true);
    setError(undefined);

    droprates({jwt, unit: selection[0].id, chances: values}).then((_response: number) => {
      if(selection[0].props.items !== undefined) {
        for(const item of Object.entries(values)) {
          selection[0].props.items[sanitize_key(item[0])].chance = item[1];
        }
      }
      setVisible(false);
      setConfirmLoading(false);
      model.setLocked(false);
    }).catch((error: AxiosError) => {
      setConfirmLoading(false);
      if(error.response) {
        setError(!error.response.data ? "Something went wrong! Please try again." : (error.response.data + "!"))
      } else {
        setError("Something went wrong! Please try again.");
      }
    });
  };

  if(selection[0].props.items !== undefined) {
    const entries = Object.entries(selection[0].props.items);
    sdrDisabled = selection[0].links.findIndex(l => l.to ? nodes[l.to].type === BalancerNodeModel.NODE_TYPE : false) >= 0 ? true : entries.length === 0;
    
  } else {
    sdrDisabled = true;
  }

  if(curPrice < 0) {
    setCurPrice(selection[0].props.price ?? 0);
  }
  
  return (
    <>
      <DropRateModal
        visible={visible}
        confirmLoading={confirmLoading}
        error={error}
        onOk={onOk}
        onCancel={onCancel}
      />
      <PushRedelModal
        visible={redelVisible}
        confirmLoading={confirmRedelLoading}
        error={redelError}
        onOk={onRedelOk}
        onCancel={onRedelCancel}
      />

      <h1>General Properties</h1>
      <div className="property"><div className="label">Region:</div><div className="value">{selection[0].props.region}</div></div>
      <div className="property">
        <div className="label">Owner Only:</div>
        <div className="value"><Checkbox disabled={timedOut} checked={nodeFlags ? (nodeFlags & FLAG_OWNERONLY) === FLAG_OWNERONLY : false} onChange={onOoChanged} /></div>
      </div>
      <div className="property">
        <div className="label">Disabled:</div>
        <div className="value"><Checkbox disabled={timedOut} checked={nodeFlags ? (nodeFlags & FLAG_DISABLED) === FLAG_DISABLED : false} onChange={onDsbChanged} /></div>
      </div>
      
      <h1>Item Properties</h1>
      <div className="property">
        <div className="label">Price:</div>
        <div className="value num-input">
          <InputNumber
            min={0}
            step={1}
            defaultValue={curPrice}
            onFocus={lockDiagram}
            onBlur={unlockDiagram}
            onChange={(num: string | number | undefined) => setCurPrice(Number(num ?? 0))}
            onKeyDown={(e) => { if (e.key === 'Enter' && curPrice !== selection[0].props.price) setPrice(); }}
          />
          <Button
            type="primary"
            loading={priceLoading}
            onClick={setPrice}>
            {priceLoading ? "" : "Ok"}
          </Button>
        </div>
      </div>
      <Button
        type="primary"
        block
        onClick={showModal}
        disabled={sdrDisabled}>
        Set Drop Rates...
      </Button>
      <h1>Analytics Properties</h1>
      <Button type="primary" block onClick={() => routeChange(`/analytics?id=${selection[0].id}`)}>
        View Analytics...
      </Button>

      <h1>Maintenance</h1>
      <Button type="primary" block onClick={showRedelModal}
        disabled={sdrDisabled && selection[0].links.length === 0}>
        Push Redelivery...
      </Button>
    </>
  )
}