import React from 'react';
import EditorView from './views/editor/EditorView';
import LoginView from './views/login/LoginView';
import { BrowserRouter } from 'react-router-dom';
import { Route } from 'react-router';
import LogoutView from './views/logout/LogoutView';
import RedeliveryView from './views/redelivery/RedeliveryView';
import ExpiredView from './views/expired/ExpiredView';
import AnalyticsView from './views/analytics/AnalyticsView';

function App() {
  return (
    <BrowserRouter basename={process.env.REACT_APP_BASE_URL || "/"}>
      <Route exact path="/">
        <EditorView />
      </Route>
      <Route exact path="/analytics">
        <AnalyticsView />
      </Route>
      <Route exact path="/expired">
        <ExpiredView />
      </Route>
      <Route exact path="/redeliver">
        <RedeliveryView />
      </Route>
      <Route exact path="/login">
        <LoginView />
      </Route>
      <Route exact path="/logout">
        <LogoutView />
      </Route>
    </BrowserRouter>
  );
}

export default App;
