import React from 'react';
import { useActions } from '../../store';
import  { Redirect } from 'react-router-dom'

// Jokes on you there is no view
export const LogoutView = () => {
  const setJWT = useActions(actions => actions.api.setJWT);
  setJWT(null);

  return (
    <Redirect to='/login'/>
  );
};

export default LogoutView;