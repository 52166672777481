import React from 'react';
import { NodeType } from "./diagramming/util";
import { ThemeProvider } from 'styled-components';

const primaryTheme = {
  colors: {
    nodes: {
      [NodeType.Gacha]: {
        primary: '#434c6f',
        portIcon: '#434c6f',
        portDot: '#434c6f'
      },
      [NodeType.Distributor]: {
        primary: '#4b7135',
        portIcon: '#8fda65',
        portDot: '#6ca54a'
      },
      [NodeType.Balancer]: {
        primary: '#943b3d',
        portIcon: '#ef6568',
        portDot: '#ff8689'
      },
      [NodeType.Voucher]: {
        primary: '#7a38d6',
        portIcon: '#8549da',
        portDot: '#8549da'
      },
      [NodeType.Vendor]: {
        primary: '#2d769a',
        portIcon: '#8fda65',
        portDot: '#6ca54a'
      }
    },
    menu: {
      background: '#232323',
      background_light: '#333333',
      border: 'rgba(255, 255, 255, 0.25)',
      text: '#fefefe'
    }
  }
}

export const GachaThemeProvider = (props: any) => (
  <ThemeProvider theme={primaryTheme} {...props} />
)

export type Theme = typeof primaryTheme;