import React from 'react';
import styled from 'styled-components';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { StyledProps } from '../../util/styled-types';

interface OpenCloseToggleProps {
  open: boolean;
  onClick: (...props: any) => any;
  orientation: 'right'|'left';
}
export const OpenCloseToggle = ({ open, onClick, orientation }: OpenCloseToggleProps) => {
  const Icon = (open && orientation === 'right') ? MenuUnfoldOutlined : MenuFoldOutlined;
  return (
    <Container onClick={onClick}>
      <Icon style={{ fontSize: '1.4rem' }} />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  width: 2.5rem;
  height: 2.5rem;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }: StyledProps) => theme.colors.menu.background};
  box-shadow: 1px 1px 2px 2px #0000001d;
  border-radius: 32px;
  cursor: pointer;
  :hover {
    background-color: ${({ theme }: StyledProps) => theme.colors.menu.background_light};
  }
  :active {
    background-color: ${({ theme }: StyledProps) => theme.colors.menu.background};
  }
`;

export default OpenCloseToggle;