import { Alert, Button, Col, Divider, Form, Input, InputNumber, Modal, Row, Slider } from "antd"
import React, { useState } from "react"
import { useStore } from "../../store";
import { normalize_key } from "../../util";

type DropRateModalProps = {
  visible: boolean,
  confirmLoading: boolean,
  error: string | undefined,
  onOk: (values: { [s: string]: number; }) => void,
  onCancel: () => void
}

export const DropRateModal = ({ visible, confirmLoading, error, onOk, onCancel }: DropRateModalProps) => {
  const selection = useStore(state => state.diagram.selection);
  const [values, setValues] = useState<{ [s: string]: number; }>({});
  const [initItems, setInitItems] = useState(0);
  const [filter, setFilter] = useState("");
  const [allSlider, setAllSlider] = useState(0);

  if(!visible) {
    if(initItems !== 0) {
      setInitItems(0);
      setAllSlider(0);
    }
    return (<></>);
  }

  let scaling = 1;
  const onChangeFilter = (s: string) => {
    setFilter((s ?? "").toLowerCase());
    setAllSlider(0);
  };

  const onChange = (k: string, v: number) => {
    if(isNaN(v)) v = 0;
    let updated = {...values};
    updated[k] = v || 0;
    let remaining = 1000;

    Object.values(updated).forEach(chance => {
      remaining = remaining - Math.floor(chance);
    });

    if(remaining < 0) {
      updated[k] = v + remaining;
    }

    setValues(updated);
  };

  const forceValues = (v: number) => {
    let updated: { [s: string]: number; } = {};

    const count = Object.keys(values).filter((k) => filter === "" || k.toLowerCase().includes(filter)).length;

    if(count === 0) return; // do nothing

    let remaining = 1000;

    Object.entries(values).forEach(item => {
      if(filter === "" || item[0].toLowerCase().includes(filter)) {
        const n = v / count;
        updated[item[0]] = Math.floor(n);
        remaining -= n;
      }
      else {
        updated[item[0]] = item[1];
        remaining -= item[1];
      }
    });
    
    if(Math.floor(remaining) < 0) {
      // We have to do it again
      v += Math.round(remaining);

      Object.entries(values).forEach(item => {
        if(filter === "" || item[0].toLowerCase().includes(filter)) {
          const n = Math.floor(v / count);
          updated[item[0]] = n;
        }
        else {
          updated[item[0]] = item[1];
        }
      });
    }
    
    setAllSlider(v);
    setValues(updated);
  };

  const formatSliderTip = (value?: number | undefined) => {
    if(!value || isNaN(value)) return "0%";
    return `${value/10}%`;
  }

  const formatSliderInput = (value: string | number | undefined) => {
    if(!value) return "%";
    if(isNaN(+value)) return "0%";
    return `${value}%`;
  }

  if(selection[0].props.items !== undefined) {
    const entries = Object.entries(selection[0].props.items);
    if(entries.length > 0 && initItems === 0) {
      let total = 0;
      entries.forEach(item => {
        total += item[1].chance || 0;
      });
      scaling = 1000 / total;
      setValues(Object.fromEntries(
        entries.map(item => 
          [normalize_key(item[0]), Math.floor((item[1].chance || 0) * scaling)]
        )
      ));
      setInitItems(1);
    }
  }

  let remaining = 1000;

  Object.values(values).forEach(chance => {
    remaining = Math.max(remaining - Math.floor(chance), 0);
  });

  // Handle cases like 33.3333 where 0.1 is left
  if(initItems === 1) {
    if(remaining > 0) {
      values[Object.keys(values)[0]] += remaining;
      remaining = 0;
      setValues(values);
    }
    setInitItems(2);
  }

  return (
    <>
      <Modal
          title="Drop Rates"
          visible={visible}
          onOk={() => onOk(values)}
          confirmLoading={confirmLoading}
          onCancel={onCancel}
          maskClosable={false}
        >
          <Alert className="drop-form-error" style={{display: typeof error === "string" ? "" : "none"}} message={error} type="error" showIcon />
          <Form
          name="basic"
          preserve={false}
          initialValues={values}
        >
          <div style={{display: "inline-block", fontSize: '12px', marginTop: "2px"}}>
            Remaining: {remaining/10}%
          </div>
          <Input
            placeholder="Type to Search"
            allowClear
            onChange={(e) => onChangeFilter(e.target.value)}
            style={{marginTop: "8px"}}
          />
          <div style={{marginTop: "12px"}}>
            <div style={{marginBottom: "6px"}}>
              <b>Set Split</b>
              <Button type="primary" size="small" style={{position: "absolute", left: "6em"}} onClick={(e) => forceValues(0)}>
                Zero
              </Button>
            </div>
            <Row>
              <Col span={19}>
                <Slider 
                onChange={(num: number) => forceValues(num)}
                tipFormatter={formatSliderTip} max={1000}
                value={allSlider} />
              </Col>
              <Col span={5}>
                <InputNumber
                  min={0}
                  max={100}
                  step={0.1}
                  style={{ margin: '0 0 0 16px', width: '4.75rem' }}
                  value={allSlider/10}
                  formatter={formatSliderInput}
                  onChange={(num: string | number | undefined) => forceValues(Number(num ?? 0)*10)}
                />
              </Col>
            </Row>
          </div>
          <Divider style={{margin: '24px 0'}}/>
          {Object.entries(values).filter((item) => filter === "" || item[0].toLowerCase().includes(filter)).map(item => (
            <Form.Item key={item[0]}>
              <div style={{marginBottom: "6px"}}>{item[0]}</div>
              <Row>
                <Col span={19}>
                  <Slider 
                  onChange={(num: number) => onChange(item[0], num)}
                  tipFormatter={formatSliderTip} max={1000}
                  value={item[1]} />
                </Col>
                <Col span={5}>
                  <InputNumber
                    min={0}
                    max={100}
                    step={0.1}
                    style={{ margin: '0 0 0 16px', width: '4.75rem' }}
                    value={item[1]/10}
                    formatter={formatSliderInput}
                    onChange={(num: string | number | undefined) => onChange(item[0], Number(num ?? 0)*10)}
                  />
                </Col>
              </Row>
            </Form.Item>
          ))}
        </Form>
      </Modal>
    </>
  )
}