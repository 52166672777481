import styled, { css } from 'styled-components';
import { Icon } from '../components/Icon';

export const NodeIcon = styled(Icon)`
  min-width: 1.25rem;
  min-height: 1.25rem;
  width: 1.25rem;
  height: 1.25rem;
  margin-right: .25rem;
`;

export const PlainIcon = styled(Icon)`
  margin: 0 auto;
  display: inline-block;
  min-width: 100%;
  min-height: 100%;
  width: 100%;
  height: 100%;
  background-size: 100%;
  background-position: center; 
`;

export interface DraggableContainerProps {
  dragging: boolean;
}

export const DraggableContainer = styled.div`
  box-shadow: .125rem .125rem 3px rgba(0, 0, 0, .5);
  cursor: grab;
  transform: none;

  &:hover {
    transition: all 100ms;
    box-shadow: .325rem .325rem 3px rgba(0, 0, 0, .5);
    transform: translate(-0.2rem, -0.2rem);
    ${({ dragging }: DraggableContainerProps) => dragging && css`
      cursor: grabbing;
      box-shadow: .125rem .125rem 3px rgba(0, 0, 0, .5);
      transform: none;
    `}
  }

  &:hover::after {
    content: '';
    display: block;
    position: absolute;
    top: .325rem;
    left: .325rem;
    width: 100%;
    height: 100%;
  }
`;

interface NodeContainerProps extends DraggableContainerProps {
  color?: string;
  selected?: boolean;
  timedOut: boolean;
}
export const NodeContainer = styled(DraggableContainer)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 10rem;
  height: 10rem;
  border-radius: .5rem;
  border: 1px solid #424242;
  background: ${({ color }: NodeContainerProps) => color ?? ''} linear-gradient(225deg,#3f698f00,#00000054);

  &:hover {
    border-color: #40E0D080;
  }

  ${({ selected }) => selected ? css`
    border: 2px solid turquoise;

    &:hover {
      border: 2px solid turquoise;
    }
  ` : null}

  ${({ timedOut }) => timedOut ? css`
    opacity: 0.6;
  ` : null}
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  height: 2rem;
  width: 10rem;
  min-width: 0;
  padding: .25rem .5rem;
  color: white;
  background-color: #0000001c;
  border-top-right-radius: .5rem;
  border-top-left-radius: .5rem;
`;
export const TitleText = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const PortIcon = styled(Icon)`
  display: block;
  width: 1.25rem;
  height: 1.25rem;
`;

export const NodeChildren = styled.div`
    color: #ddd;
    font-size: 10px;
    line-height: 14px;
    padding-top: 2px;
    padding-left: 10px;
    padding-right: 10px;
    max-height: 7rem;
    overflow: hidden;
    width: 9.9rem;
`;

export const NodeChildItem = styled.li`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const NodeChildList = styled.ul`
  list-style: none;
  padding-left: 0;
`;

interface NodePortIconContainerProps { color?: string }
export const NodePortIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2rem;
  width: 2rem;
  border-radius: 0.5rem;

  &::before {
    content: '';
    position: absolute;
    height: 2rem;
    width: 2rem;
    background-color: ${({ color }: NodePortIconContainerProps) => color ?? 'black'};
    opacity: 0.25;
    border: 1px solid white;
    border-radius: 0.5rem;
    z-index: -1;
    transition: 200ms opacity;
  }

  &:hover {
    &::before {
      opacity: 0.5;
    }
  }
`;

export const Port = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translateY(-1rem);
  margin: 0 .25rem;

  &:hover ${NodePortIconContainer}::before {
    opacity: 0.5;
  }
`;

export const PortCircleContainer = styled.div`
  width: 1.25rem;
  height: 1.25rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  margin-top: 0.25rem;
`;

export interface PortCircleProps { color?: string }
export const PortCircle = styled.div`
  width: .55rem;
  height: .55rem;
  border-radius: .5rem;
  background-color: ${({ color }: PortCircleProps) => color ?? 'darkgray'};
  box-shadow: 0 0 .5rem .1rem rgba(255, 255, 255, .25);
`;

export const PortContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
  justify-content: center;
  flex-direction: row;
`;