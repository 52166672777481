import { DefaultPortModel, DefaultPortModelOptions, PortModel } from "@gacha/gacha-diagrams";
import { GachaNodeModel, VendorNodeModel } from "../nodes";

export interface ConstrainedPortModelOptions extends DefaultPortModelOptions{
  nodeTypeConstraint?: string;
  noDuplicateLinks?: boolean;
}

export class ConstrainedPortModel extends DefaultPortModel {
  static type = 'constrained-port';

  nodeTypeConstraint?: string;
  maxConnections?: number;
  noDuplicateLinks?: boolean;

  constructor(options: ConstrainedPortModelOptions) {
    super(options);
    this.nodeTypeConstraint = options.nodeTypeConstraint;
    this.noDuplicateLinks = options.noDuplicateLinks;
  }

  canLinkToPort(port: PortModel): boolean {
    if (this.nodeTypeConstraint && port.getNode().getType() !== this.nodeTypeConstraint && !(port.getNode().getType() === VendorNodeModel.NODE_TYPE && this.nodeTypeConstraint === GachaNodeModel.NODE_TYPE)) {
      return false;
    }

    if(this.noDuplicateLinks && Object.values(this.getLinks()).some(link =>
      link.getSourcePort() && link.getTargetPort()
      && ((link.getSourcePort().getID() === port.getID()
      && link.getTargetPort().getID() === this.getID())
      || (link.getSourcePort().getID() === this.getID()
      && link.getTargetPort().getID() === port.getID()))
    )) {
      return false;
    }

    // Check the opposite end of the connection if it also agrees that we match up
    if (port instanceof ConstrainedPortModel) {
      const constrainedPort = port as ConstrainedPortModel;
      if(constrainedPort.nodeTypeConstraint && this.getNode().getType() !== constrainedPort.nodeTypeConstraint && !(this.getNode().getType() === VendorNodeModel.NODE_TYPE && constrainedPort.nodeTypeConstraint === GachaNodeModel.NODE_TYPE)) {
          return false;
      }

      if (port.noDuplicateLinks || this.noDuplicateLinks) {
        if(Object.values(port.getLinks()).some(link =>
          link.getSourcePort() && link.getTargetPort()
          && ((link.getSourcePort().getID() === port.getID()
          && link.getTargetPort().getID() === this.getID())
          || (link.getSourcePort().getID() === this.getID()
          && link.getTargetPort().getID() === port.getID()))
        )) {
          return false;
        }
      }
    }

    return super.canLinkToPort(port);
  }
}