import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useActions, useStore } from '../../store';
import gridBg from '../../assets/editor_bg.png';
import  { Redirect, useHistory, useLocation } from 'react-router-dom'
import { Spin, Card, Menu } from 'antd';
import { FundFilled, HomeFilled, OrderedListOutlined, PieChartOutlined } from '@ant-design/icons';
import { AxiosError } from 'axios';
import { useRef } from 'react';
import MenuButton from '../../components/ContextMenu/MenuButton';
import { StyledProps } from '../../util/styled-types';
import { Tooltip } from 'react-tippy';
import { isChrome } from '../../util';
import { MainDash } from './dashes/Main';
import { EntriesDash } from './dashes/Entries';
import Sider from 'antd/lib/layout/Sider';
import smLogo from './img/tetra-logo-sm.png';

export interface PieData { x: string; y: number; }

export interface Transaction {
  /**
   * Name of item sent
   */
  item: string,

  /**
   * User key of sender (You)
   */
  owner: string,

  pos?: {x: number, y: number, z: number},
  price?: number,
  region?: string,
  time: number,
  type?: number,

  /**
   * User key of receiver
   */
  user: string,

  /**
   * User name of receiver
   */
  uname: string,

  /**
   * Unit that interfaced (and possibly fullfilled) the request
   */
  unit: {$oid: string, name?: string},

  /**
   * Balancer that fullfilled the request
   */
  bal?: {$oid: string},

  /**
   * Refund status
   */
  refund?: number,

  _id: {$oid: string},

  /**
   * Needed for tables
   */
  key?: string,
}

export const AnalyticsView = () => {
  const getAnalytics = useActions(state => state.api.getAnalytics);
  const getUnitNames = useActions(state => state.api.getUnitNames);
  const jwt = useStore(state => state.api.jwt);
  let initialized = useRef(0);
  const [dashView, setDashView] = useState("main");
  const [unitNameCache, setUnitNameCache] = useState<{[s: string]: string} | null>(null);
  const [analytics, setAnalytics] = useState<Transaction[] | null>(null);
  const [nameReduced, setNameReduced] = useState<({cnt?: number, earn?: number} & Transaction)[]>([]);
  const [regionReduced, setRegionReduced] = useState<({cnt?: number, earn?: number} & Transaction)[]>([]);
  const [timeReduced, setTimeReduced] = useState<{Plays: number, Date: string, time: number, Earnings: number}[]>([]);
  const history = useHistory();
  const [didMount, setDidMount] = useState(false); 
  const query = new URLSearchParams(useLocation().search);
  const machine_id = query.get("id");

  useEffect(() => {
    setDidMount(true);
    return () => setDidMount(false);
  }, [])

  if(!didMount) {
    return (
      <ViewContainer>
        <Card><Spin /></Card>
      </ViewContainer>
    );
  }

  if(jwt === null) {
    return (
      <Redirect to='/login'/>
    );
  }

  const routeChange = (route: string) =>{ 
    history.push(route);
  }

  if(initialized.current === 0) {
    initialized.current = 1;
    getAnalytics(jwt).then((response: Transaction[]) => {
      setAnalytics(response.sort((a, b) => a.time - b.time).map(t => {
        return {key: t._id.$oid, ...t};
      }));
    })
    .catch((error: AxiosError) => {
      history.push('/login');
    });
  }
  
  if(analytics === null) {
    return (
      <ViewContainer style={{ textAlign: "center" }}>
        <Card style={{ display: "inline-block", marginTop: "24%" }}><Spin /></Card>
      </ViewContainer>
    );
  }
  
  

  if(!machine_id || machine_id.length === 0) {
    if(initialized.current === 1 && analytics) {
      initialized.current = 2;
      const playFiltered = analytics.filter((a) => a.type === undefined || a.type === 0);
      let tmpNameReduced: ({cnt?: number, earn?: number} & Transaction)[] = playFiltered.filter((a, b) => playFiltered.findIndex(t => t.unit.$oid === a.unit.$oid) === b).map(a => {return {...a};});
      let tmpRegionReduced: ({cnt?: number, earn?: number} & Transaction)[] = playFiltered.filter((a, b) => playFiltered.findIndex(t => t.region && t.region.length > 0 && t.region === a.region) === b).map(a => {return {...a};});
      getUnitNames({jwt, units: tmpNameReduced.map(t => { return t.unit.$oid; })}).then((response: {[s: string]: string}) => {
        setUnitNameCache(response);
        Object.entries(response).forEach(v => {
          const ind = tmpNameReduced.findIndex(t => t.unit.$oid === v[0]);
          if(ind > 0 && v[1].length > 0) {
            tmpNameReduced[ind].unit.name = v[1];
          }
        });
        initialized.current = 3;
        setNameReduced(tmpNameReduced.filter((a, b) => {
          const ind = tmpNameReduced.findIndex(t => t.unit.name === a.unit.name);
          if(ind !== b) {
            const rside = tmpNameReduced[b].cnt ?? 0;
            tmpNameReduced[ind].cnt = (tmpNameReduced[ind].cnt ?? 0) + rside;
            return false;
          }
          return a.unit.name && ind === b;
        }));
      });
      
      let tmpTimeReduced: {Plays: number, Date: string, time: number, Earnings: number}[] = [];
      let secs = Date.now() / 1000;
      while(secs >= analytics[0].time - 172799) {
        tmpTimeReduced.unshift({Plays: 0, Date: new Date(secs*1000).toLocaleDateString(), time: secs, Earnings: 0});
        secs -= 86400;
      }
      
      analytics.forEach((t, i) => {
        const indN = tmpNameReduced.findIndex(rt => rt.unit.$oid === t.unit.$oid);
        if(indN >= 0) {
          tmpNameReduced[indN].cnt = (tmpNameReduced[indN].cnt ?? 0) + 1;
          tmpNameReduced[indN].earn = (tmpNameReduced[indN].earn ?? 0) + (t.price ?? 0);
        }
        const indR = tmpRegionReduced.findIndex(rt => rt.region && rt.region === t.region);
        if(indR >= 0) {
          tmpRegionReduced[indR].cnt = (tmpRegionReduced[indR].cnt ?? 0) + 1;
          tmpRegionReduced[indR].earn = (tmpRegionReduced[indR].earn ?? 0) + (t.price ?? 0);
        }
        const date = new Date(t.time*1000).toLocaleDateString();  
        const indT = tmpTimeReduced.findIndex(rt => rt.Date === date);
        if(indT >= 0) {
          tmpTimeReduced[indT].Plays += 1; // TODO: Set time right?
          tmpTimeReduced[indT].Earnings += t.price ?? 0;
        }
      });
      
      setTimeReduced(tmpTimeReduced);
      setRegionReduced(tmpRegionReduced);
    }

    let getView = () => {
      switch(dashView) {
        case "main":
          return (<MainDash nameReduced={nameReduced} regionReduced={regionReduced} timeReduced={timeReduced} />);
        case "entries":
          return (<EntriesDash analytics={analytics} initialized={initialized} unitNameCache={unitNameCache} />);
      }
    }
    
    return (
      <>
      <NavContainer>
        <Tooltip offset={isChrome?-20:0} distance={isChrome?-30:10} title="Home" position="bottom" theme="light" delay={500}>
          <MenuButton Icon={HomeFilled} onClick={() => routeChange('/')} />
        </Tooltip>
      </NavContainer>
      <ViewContainer>
        <CatContainer>
          <LogoSmall />
          <Sider width="100%">
            <Menu defaultSelectedKeys={['main']} mode="inline" onSelect={(sel) => { setDashView(sel.key.toString()); }}>
              <Menu.Item key="main" icon={<PieChartOutlined />}>
                Dashboard
              </Menu.Item>
              <Menu.Item key="entries" icon={<OrderedListOutlined />}>
                History
              </Menu.Item>
            </Menu>
          </Sider>
        </CatContainer>
        <DashWrap>
          <DashContainer>
            {getView()}
          </DashContainer>
        </DashWrap>
      </ViewContainer>
      </>
    );
  } else { // Machine ID set
    const machine_analytics = analytics.filter(t => t.unit.$oid === machine_id);

    if(initialized.current === 1 && analytics) {
      initialized.current = 2;
      getUnitNames({jwt, units: [machine_id]}).then((response: {[s: string]: string}) => {
        initialized.current = 3;
        setUnitNameCache(response);
      });
    }

    return (
      <>
      <NavContainer>
        <MenuButton Icon={HomeFilled} onClick={() => routeChange('/')} style={{marginBottom: "6px"}} />
        <MenuButton onClick={() => {initialized.current = 0;routeChange("/analytics");}} Icon={FundFilled} />
      </NavContainer>
      <ViewContainer style={{padding: "30px"}}>
        <EntriesDash analytics={machine_analytics} initialized={initialized} unitNameCache={unitNameCache} />
      </ViewContainer>
      </>
    );
  }
};

// TODO: Mobile support

const CatContainer = styled.div`
  width: 12%;
  min-width: 160px;
  height: 100%;
  position: fixed;
  background-color: #fff;
  border-right: 1px solid #f0f0f0;
`;

const DashWrap = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  left: max(160px, 12%);
  overflow: auto;
`;

const DashContainer = styled.div`
  width: 100%;
  min-width: 970px;
  padding: 30px 2%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const ViewContainer = styled.div`
  width: 100%;
  height: 100%;
  background: url('${gridBg}');
  position: relative;
`;

const NavContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1000;
  margin: 14px 25px;
  color: ${({ theme }: StyledProps) => theme.colors.menu.text};
`;

const LogoSmall = styled.div`
  background: url('${smLogo}');
  background-repeat: no-repeat;
  background-size: auto 170%;
  background-position: 16px -27px;
  width: 100%;
  height: 80px;
  display: block;
`;

export const FlexBreak = styled.div`
  flex-basis: 100%;
  height: 0;
`;

export default AnalyticsView;