import { Button } from "antd";
import { AxiosError } from "axios";
import React, { useState } from "react"
import { useActions, useStore } from "../../store";
import { SharesModal } from "./sharesmodal";

export const DistContextMenu = () => {
  const selection = useStore(state => state.diagram.selection);
  const shares = useActions(state => state.api.shares);
  const jwt = useStore(state => state.api.jwt);
  const model = useStore(state => state.diagram.model);
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);

  const onCancel = () => {
    setVisible(false);
    unlockDiagram();
    setError(undefined);
  };

  const onOk = (values: { _id: string, share: number }[]) => {
    setConfirmLoading(true);
    setError(undefined);

    shares({jwt, unit: selection[0].id, shares: values}).then((response: number) => {
      selection[0].props.shares = values.filter(v => v.share > 0);
      setVisible(false);
      model.setLocked(false);
    }).catch((error: AxiosError) => {
      if(error.response) {
        setError(!error.response.data ? "Something went wrong! Please try again." : (error.response.data + "!"));
      } else {
        setError("Something went wrong! Please try again.");
      }
    }).finally(() => {
      setConfirmLoading(false);
    });
  };
  
  if(selection.length === 0) {
    return (<></>);
  }

  const lockDiagram = () => {
    model.setLocked(true);
  };

  const unlockDiagram = () => {
    model.setLocked(false);
  };

  const showModal = () => {
    setVisible(true);
    lockDiagram();
  };


  return (
    <>
      <SharesModal
        visible={visible}
        confirmLoading={confirmLoading}
        error={error}
        onOk={onOk}
        onCancel={onCancel}
      />
      <h1>General Properties</h1>
      <div className="property"><div className="label">Region:</div><div className="value">{selection[0].props.region}</div></div>
      <h1>Share Properties</h1>
      <Button
        type="primary"
        block
        onClick={showModal}>
        Set Shares...
      </Button>
    </>
  )
}