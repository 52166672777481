import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Alert, Form, Modal, Row, Select } from "antd"
import { AxiosError } from "axios";
import React, { useState } from "react"
import { useHistory } from "react-router-dom";
import { BalancerNodeModel, PERM_COPY, PERM_MODIFY, PERM_TRANSFER } from "../../diagramming/nodes";
import { useActions, useStore } from "../../store";
import { Transaction } from "../../views/analytics/AnalyticsView";
const { Option } = Select;
const ALPHABETICAL_LAST = "zzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzz";

type PushRedelModalProps = {
  visible: boolean,
  confirmLoading: boolean,
  error: string | undefined,
  dUnit?: string,
  onOk: (values: { unit: string, oldItem: string, newItem: string, fnc: boolean }) => void,
  onCancel: () => void
}

export const PushRedelModal = ({ visible, confirmLoading, error, onOk, onCancel, dUnit }: PushRedelModalProps) => {
  const getAnalytics = useActions(state => state.api.getAnalytics);
  const selection = useStore(state => state.diagram.selection);
  const nodes = useStore(state => state.diagram.nodes);
  const jwt = useStore(state => state.api.jwt);
  const [unit, setUnit] = useState<string>(dUnit ?? "");
  const [oldItem, setOldItem] = useState<string>("");
  const [item, setItem] = useState<string>("");
  const [isCopy, setIsCopy] = useState<boolean>(true);
  const [analytics, setAnalytics] = useState<Transaction[] | null>(null);
  const history = useHistory();

  if(!visible) {
    return (<></>);
  }

  const innerOk = (unit: string, oldItem: string, newItem: string, conf: boolean) => {
    if(!isCopy && !conf) {
      Modal.confirm({
        title: 'Warning!',
        icon: <ExclamationCircleOutlined />,
        content: 'The item you have selected is not copyable. This means customers will receive a copy of a no copy item. If you are redelivering an item they already have, they will have two copies.',
        okText: 'Confirm',
        cancelText: 'Cancel',
        onOk: () => innerOk(unit, oldItem, newItem, true),
      });
      return;
    }
    onOk({unit, oldItem, newItem, fnc: conf});
  }
  
  const onUnitSelect = (value: string) => {
    if(!nodes[value]) {
      return;
    }
    setUnit(value);
  };
  
  const onItemSelect = (value: string) => {
    const items = nodes[unit].props.items;
    if(items)
      setIsCopy((items[value].perm & PERM_COPY) === PERM_COPY);
    setItem(value);
  };

  let innerForm = [
    <Row>
      Via Node:
      <Select
          defaultValue={unit === "" ? undefined : unit}
          disabled={selection[0].type === BalancerNodeModel.NODE_TYPE}
          style={{width: "100%"}}
          showSearch
          placeholder="Type to Search"
          optionFilterProp="search"
          onSelect={onUnitSelect}
          filterSort={(optionA: any, optionB: any) =>
            {
              if(!optionA.search) return 1;
              if(!optionB.search) return -1;
              return optionA.search.toLowerCase().localeCompare(optionB.search.toLowerCase());
            }
          }
        >
          {
            selection[0].type === BalancerNodeModel.NODE_TYPE ? (
              <Option key={selection[0].id} value={selection[0].id} search={(selection[0].name.trimStart() === "" ? ALPHABETICAL_LAST : selection[0].name) + selection[0].id}>
                {selection[0].name !== " " ? (<><b>{selection[0].name}</b> (<small style={{fontFamily: "monospace"}}>{selection[0].id.substring(selection[0].id.length-7).toUpperCase()}</small>)</>) : (<span style={{fontFamily: "monospace"}}>{selection[0].id.substring(selection[0].id.length-7).toUpperCase()}</span>)}
              </Option>
              ) : [{from: selection[0].id, to: selection[0].id}, ...selection[0].links].map((item) => {
                const to = item.to;
                if(to) {
                  const node = nodes[to];
                  return (
                    <Option key={to} value={to} search={(node.name.trimStart() === "" ? ALPHABETICAL_LAST : node.name) + to}>
                      {node.name !== " " ? (<><b>{node.name}</b> (<small style={{fontFamily: "monospace"}}>{to.substring(to.length-7).toUpperCase()}</small>)</>) : (<span style={{fontFamily: "monospace"}}>{to.substring(to.length-7).toUpperCase()}</span>)}
                    </Option>
                  );
                } else {
                  return (<></>);
                }
            })
          }
        </Select>
    </Row>
  ];

  let filter_item = [];

  if(unit !== "") {
    if(!analytics)
      getAnalytics(jwt).then((response: Transaction[]) => {
        setAnalytics(response);
      })
      .catch((error: AxiosError) => {
        history.push('/login');
      });
    else {
      let unique_ana_items = analytics.filter(t => t.refund === undefined).filter((t, i, s) => s.findIndex(b => t.item === b.item) === i);
      innerForm.push(
        <Row style={{ marginTop: "16px" }}>
          Users who purchased:
          <Select
            defaultValue={oldItem === "" ? undefined : oldItem}
            style={{width: "100%"}}
            showSearch
            placeholder="Type to Search"
            optionFilterProp="search"
            onSelect={setOldItem}
            filterSort={(optionA: any, optionB: any) =>
              {
                if(!optionA.search) return 1;
                if(!optionB.search) return -1;
                return optionA.search.toLowerCase().localeCompare(optionB.search.toLowerCase());
              }
            }
          >
            {
              unique_ana_items.map((t) => {
                let item = t.item;
                return (
                  <Option key={item} value={item} search={item === " " ? ALPHABETICAL_LAST : item}>
                    <b>{item}</b>
                  </Option>
                );
              })
            }
          </Select>
        </Row>
      );

      if(oldItem !== "") {
        filter_item = analytics.filter(t => t.item === oldItem && t.refund === undefined).filter((t, i, s) => s.findIndex(b => t.user === b.user) === i);
        innerForm.push(
          <Row style={{ marginTop: "16px" }}>
            Will receive:
            <Select
              defaultValue={item === "" ? undefined : item}
              style={{width: "100%"}}
              showSearch
              placeholder="Type to Search"
              optionFilterProp="search"
              onSelect={onItemSelect}
              filterSort={(optionA: any, optionB: any) =>
                {
                  if(!optionA.search) return 1;
                  if(!optionB.search) return -1;
                  return optionA.search.toLowerCase().localeCompare(optionB.search.toLowerCase());
                }
              }
            >
              {
                Object.entries(nodes[unit].props.items ?? {}).map((item) => (
                    <Option key={item[0]} value={item[0]} search={item[0] === " " ? ALPHABETICAL_LAST : item[0]}>
                      <b>{item[0]}</b> <small style={{fontFamily: "monospace"}}>{item[1].perm & PERM_COPY ? '' : '(no copy)'}{item[1].perm & PERM_MODIFY ? '' : '(no modify)'}{item[1].perm & PERM_TRANSFER ? '' : '(no transfer)'}</small>
                    </Option>
                  ))
              }
            </Select>
          </Row>
        );
        innerForm.push(
          <Row style={{ marginTop: "16px" }}>
            <b>Redeliveries to make:</b>&nbsp;{analytics ? filter_item.length : "Loading..."}
          </Row>
        );
        innerForm.push(
          <Row>
            <b>Estimated completion time:</b>&nbsp;{analytics ? filter_item.length*4 : "Loading..."} seconds
          </Row>
        );
      }
    }
  }

  return (
    <>
      <Modal
          title="Push Redelivery"
          visible={visible}
          onOk={() => innerOk(unit, oldItem, item, false)}
          confirmLoading={confirmLoading}
          onCancel={onCancel}
          maskClosable={false}
          okButtonProps={{disabled: unit === "" || oldItem === "" || item === "" || !analytics || filter_item.length === 0}}
        >
          <Alert className="drop-form-error" style={{display: typeof error === "string" ? "" : "none"}} message={error} type="error" showIcon />
          <Form
          name="basic"
          preserve={false}
        >
          {innerForm}
        </Form>
      </Modal>
    </>
  )
}