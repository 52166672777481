import axios, { AxiosRequestConfig } from "axios";
import { normalize_key } from "../util";
import { Transaction } from "../views/analytics/AnalyticsView";

const apiLocation = "https://api.tetra.network";
const defaultErrorResponse = "Something went wrong. Please try again later.";

export class ApiClient {
  /**
   *  Returns the JSON containing JWT and reset pass state on success.
   *  Throws an Error type on failure.
   *  TODO: Probably have a proper error type with more details
   *  */
  async login(username: string, password: string) {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }

      const data = `u=${username}&p=${password}`;

      const response = await axios.post(`${apiLocation}/login`, data, config);
      const json = response.data;

      // Error should never exist in 2xx status range but just in case.
      // jwt should exist if error does not.
      if(json.error === undefined) {
        return json;
      } else {
        throw new Error(json.error);
      }
    } catch(error) {
      // Status codes that are not 2xx
      if (error.response) {
        // Request made and server responded
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        const json = error.response.data;
        if(json.error !== undefined) {
          throw new Error(json.error);
        } else {
          throw new Error(defaultErrorResponse);
        }
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
        throw new Error(defaultErrorResponse);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
        throw new Error(defaultErrorResponse);
      }
    }
  }

  /**
   * An error from here probably means we need to login.
   * Returns a promise so we can .then() and .catch() by ourselves
   */
  async getUnits(jwt: string|null): Promise<[]> {
    let config: AxiosRequestConfig = {
      headers: {
        'Authorization': `Bearer ${jwt}`,
      }
    }

    if(jwt === null) {
      console.log("No JWT set");
      config = {}; // No auth to provide
    }

    // Very small wrapper to remove some needless stuff from the return value
    try {
      const response = await axios.post(`${apiLocation}/api/v1/get_units`, null, config);
      return response.data;
    } catch(error) {
      throw error;
    }
  }

  /**
   * 
   * @param jwt 
   * @param gachaOrVoucher Mongo id of gacha or voucher side of link
   * @param other Mongo id to link to gachaOrVoucher
   * @param otherType type of other (See: https://gitlab.com/gachapensl/webserver/-/wikis/Enums )
   */
  async attach(jwt: string|null, gachaOrVoucher: string, other: string, otherType: number): Promise<number> {
    let config: AxiosRequestConfig = {
      headers: {
        'Authorization': `Bearer ${jwt}`,
      }
    }

    if(jwt === null) {
      console.log("No JWT set");
      config = {}; // No auth to provide
    }

    // Very small wrapper to remove some needless stuff from the return value
    try {
      const response = await axios.post(`${apiLocation}/api/v1/attach/${other}/${gachaOrVoucher}?ty=${otherType}`, null, config);
      return Number(response.data);
    } catch(error) {
      throw error;
    }
  }

  /**
   * 
   * @param jwt 
   * @param gachaOrVoucher Mongo id of gacha or voucher side of link
   * @param other Mongo id to link to gachaOrVoucher
   * @param otherType type of other (See: https://gitlab.com/gachapensl/webserver/-/wikis/Enums )
   */
  async detach(jwt: string|null, gachaOrVoucher: string, other: string, otherType: number): Promise<number> {
    let config: AxiosRequestConfig = {
      headers: {
        'Authorization': `Bearer ${jwt}`,
      }
    }

    if(jwt === null) {
      console.log("No JWT set");
      config = {}; // No auth to provide
    }

    // Very small wrapper to remove some needless stuff from the return value
    try {
      const response = await axios.post(`${apiLocation}/api/v1/detach/${other}/${gachaOrVoucher}?ty=${otherType}`, null, config);
      return Number(response.data);
    } catch(error) {
      throw error;
    }
  }

  /**
   * 
   * @param jwt 
   * @param unit Mongo id of unit
   * @param chances KV pairs of items and their chances that adds up to 1000 (Or else will be rejected)
   */
  async droprates(jwt: string|null, unit: string, chances: { [s: string]: number; }): Promise<number> {
    let config: AxiosRequestConfig = {
      headers: {
        'Authorization': `Bearer ${jwt}`,
      }
    }

    if(jwt === null) {
      console.log("No JWT set");
      config = {}; // No auth to provide
    }

    let data: string[] = Object.entries(chances).map(item => 
      encodeURIComponent(item[0]) + "=" + encodeURIComponent(item[1] || 0)
    );
    
    // Very small wrapper to remove some needless stuff from the return value
    try {
      const response = await axios.post(`${apiLocation}/api/v1/droprates/${unit}`, data.join("&"), config);
      return Number(response.data);
    } catch(error) {
      throw error;
    }
  }

  /**
   * 
   * @param jwt 
   * @param unit Mongo id of unit
   * @param chances KV pairs of items and their settings, can't contain pipes
   */
  async vendset(jwt: string|null, unit: string, settings: { [s: string]: { tex?: string, price?: number }; }): Promise<number> {
    let config: AxiosRequestConfig = {
      headers: {
        'Authorization': `Bearer ${jwt}`,
      }
    }

    if(jwt === null) {
      console.log("No JWT set");
      config = {}; // No auth to provide
    }

    let data: string[] = Object.entries(settings).map(item => 
      encodeURIComponent(item[0]) + "=" + encodeURIComponent(item[1].tex || "") + "|" + encodeURIComponent(item[1].price || 0)
    );
    
    // Very small wrapper to remove some needless stuff from the return value
    try {
      const response = await axios.post(`${apiLocation}/api/v1/vendset/${unit}`, data.join("&"), config);
      return Number(response.data);
    } catch(error) {
      throw error;
    }
  }

  /**
   * 
   * @param jwt 
   * @param unit Mongo id of unit
   * @param item item name
   * @param fnc force no copy
   */
  async pushRedelivery(jwt: string|null, unit: string, oldItem: string, newItem?: string, fnc?: boolean): Promise<number> {
    let config: AxiosRequestConfig = {
      headers: {
        'Authorization': `Bearer ${jwt}`,
      }
    }

    if(jwt === null) {
      console.log("No JWT set");
      config = {}; // No auth to provide
    }
    
    // Very small wrapper to remove some needless stuff from the return value
    try {
      await axios.post(`${apiLocation}/api/v1/push_redeliver`, `unit=${unit}&it=${encodeURIComponent(oldItem)}` + (newItem ? `&nit=${encodeURIComponent(newItem)}` : "") + (fnc ? "&fnc=1" : ""), config);
      return 1;
    } catch(error) {
      throw error;
    }
  }

  /**
   * 
   * @param jwt 
   * @param unit Mongo id of unit
   * @param shares KV pairs of items and their chances that adds up to 1000 (Or else will be rejected)
   */
  async shares(jwt: string|null, unit: string, shares: { _id: string, share: number }[]): Promise<number> {
    let config: AxiosRequestConfig = {
      headers: {
        'Authorization': `Bearer ${jwt}`,
      }
    }

    if(jwt === null) {
      console.log("No JWT set");
      config = {}; // No auth to provide
    }

    let data: string[] = shares.map(item => 
      encodeURIComponent(item._id) + "=" + encodeURIComponent(item.share || 0)
    );
    
    // Very small wrapper to remove some needless stuff from the return value
    try {
      const response = await axios.post(`${apiLocation}/api/v1/shares/${unit}`, data.join("&"), config);
      return Number(response.data);
    } catch(error) {
      throw error;
    }
  }

  /**
   * 
   * @param jwt 
   * @param id Mongo id of unit to delete
   */
  async deleteUnit(jwt: string|null, id: string): Promise<number> {
    let config: AxiosRequestConfig = {
      headers: {
        'Authorization': `Bearer ${jwt}`,
      }
    }

    if(jwt === null) {
      console.log("No JWT set");
      config = {}; // No auth to provide
    }

    // Very small wrapper to remove some needless stuff from the return value
    try {
      const response = await axios.post(`${apiLocation}/api/v1/del_unit/${id}`, null, config);
      return Number(response.data);
    } catch(error) {
      throw error;
    }
  }

  /**
   * 
   * @param jwt 
   * @param id Mongo id of unit to set
   * @param price Price to set
   */
  async setPrice(jwt: string|null, id: string, price: number): Promise<number> {
    let config: AxiosRequestConfig = {
      headers: {
        'Authorization': `Bearer ${jwt}`,
      }
    }

    if(jwt === null) {
      console.log("No JWT set");
      config = {}; // No auth to provide
    }

    // Very small wrapper to remove some needless stuff from the return value
    try {
      const response = await axios.post(`${apiLocation}/api/v1/price/${id}`, `p=${price}`, config);
      const resp: string[] = response.data.split("|");
      // TODO: Status not 200?

      return Number(resp[1]);
    } catch(error) {
      throw error;
    }
  }

  /**
   * 
   * @param jwt 
   * @param aliases KV pairs of uuids and their aliases
   */
  async setAliases(jwt: string|null, aliases: { [s: string]: string; }): Promise<number> {
    if(Object.keys(aliases).length === 0) {
      return 0; // 0 means nothing was updated
    }

    let config: AxiosRequestConfig = {
      headers: {
        'Authorization': `Bearer ${jwt}`,
      }
    }

    if(jwt === null) {
      console.log("No JWT set");
      config = {}; // No auth to provide
    }

    let data: string[] = Object.entries(aliases).map(item => 
      encodeURIComponent(item[0]) + "=" + encodeURIComponent(item[1])
    );
    
    // Very small wrapper to remove some needless stuff from the return value
    try {
      const response = await axios.post(`${apiLocation}/api/v1/set_uuid_alias`, data.join("&"), config);
      return Number(response.data);
    } catch(error) {
      throw error;
    }
  }

  /**
   * 
   * @param jwt 
   */
  async getAliases(jwt: string|null, uuids: string[]): Promise<{[s: string]: string}> {
    if(uuids.length === 0) {
      return {};
    }

    let config: AxiosRequestConfig = {
      headers: {
        'Authorization': `Bearer ${jwt}`,
      }
    }

    if(jwt === null) {
      console.log("No JWT set");
      config = {}; // No auth to provide
    }
    
    // Very small wrapper to remove some needless stuff from the return value
    try {
      const response = await axios.post(`${apiLocation}/api/v1/get_uuid_alias`, uuids.join("&"), config);
      return response.data;
    } catch(error) {
      throw error;
    }
  }

  /**
   * 
   * @param jwt 
   * @param id Mongo id of unit to set
   * @param item Item to set (Should exist in a linked balancer [unchecked])
   * @param as_name Name should be autoset to refer to the item
   */
  async setVoucherItem(jwt: string|null, id: string, item: string, as_name: boolean): Promise<number> {
    let config: AxiosRequestConfig = {
      headers: {
        'Authorization': `Bearer ${jwt}`,
      }
    }

    if(jwt === null) {
      console.log("No JWT set");
      config = {}; // No auth to provide
    }

    // Very small wrapper to remove some needless stuff from the return value
    try {
      const response = await axios.post(`${apiLocation}/api/v1/voucher_item/${id}`, `it=${encodeURIComponent(item)}` + (as_name ? "&as_name=1" : ""), config);
      // TODO: Status not 200?
      return Number(response.data);
    } catch(error) {
      throw error;
    }
  }

  /**
   * 
   * @param jwt redelivery jwt only
   */
  async getRedeliveries(jwt: string|null): Promise<any> {
    let config: AxiosRequestConfig = {
      headers: {
        'Authorization': `Bearer ${jwt}`,
      }
    }

    if(jwt === null) {
      console.log("No JWT set");
      config = {}; // No auth to provide
    }

    // Very small wrapper to remove some needless stuff from the return value
    try {
      const response = await axios.post(`${apiLocation}/api/v1/redeliveries`, null, config);
      // TODO: Status not 200?
      return response.data;
    } catch(error) {
      throw error;
    }
  }

  /**
   * 
   * @param jwt redelivery jwt only
   * @param item
   */
  async redeliverItem(jwt: string|null, item: string): Promise<string> {
    let config: AxiosRequestConfig = {
      headers: {
        'Authorization': `Bearer ${jwt}`,
      }
    }

    if(jwt === null) {
      console.log("No JWT set");
      config = {}; // No auth to provide
    }

    // Very small wrapper to remove some needless stuff from the return value
    try {
      const response = await axios.post(`${apiLocation}/api/v1/redeliver`, `it=${encodeURIComponent(item)}`, config);
      // TODO: Status not 200?
      return response.data;
    } catch(error) {
      throw error;
    }
  }

  /**
   * Flags should be toggled after using this
   * @param jwt 
   * @param id Mongo id of unit to set
   */
  async toggleOwnerOnly(jwt: string|null, id: string, enabled: boolean): Promise<string> {
    let config: AxiosRequestConfig = {
      headers: {
        'Authorization': `Bearer ${jwt}`,
      }
    }

    if(jwt === null) {
      console.log("No JWT set");
      config = {}; // No auth to provide
    }

    // Very small wrapper to remove some needless stuff from the return value
    try {
      const response = await axios.post(`${apiLocation}/api/v1/owneronly/${id}`, `e=${enabled ? "1" : "0"}`, config);
      // TODO: Status not 200?
      return response.data;
    } catch(error) {
      throw error;
    }
  }

  /**
   * Flags should be toggled after using this
   * @param jwt 
   * @param id Mongo id of unit to set
   */
  async toggleDisabled(jwt: string|null, id: string, enabled: boolean): Promise<string> {
    let config: AxiosRequestConfig = {
      headers: {
        'Authorization': `Bearer ${jwt}`,
      }
    }

    if(jwt === null) {
      console.log("No JWT set");
      config = {}; // No auth to provide
    }

    // Very small wrapper to remove some needless stuff from the return value
    try {
      const response = await axios.post(`${apiLocation}/api/v1/disable/${id}`, `e=${enabled ? "1" : "0"}`, config);
      // TODO: Status not 200?
      return response.data;
    } catch(error) {
      throw error;
    }
  }

  /**
   * An error from here probably means we need to login.
   * Returns a promise so we can .then() and .catch() by ourselves
   */
  async getAnalytics(jwt: string|null): Promise<Transaction[]> {
    let config: AxiosRequestConfig = {
      headers: {
        'Authorization': `Bearer ${jwt}`,
      }
    }

    if(jwt === null) {
      console.log("No JWT set");
      config = {}; // No auth to provide
    }

    // Very small wrapper to remove some needless stuff from the return value
    try {
      const response = await axios.post(`${apiLocation}/api/v1/get_analytics`, null, config);
      return response.data.map((t: Transaction) => {
        if(t.item)
          t.item = normalize_key(t.item);
        return t;
      });
    } catch(error) {
      throw error;
    }
  }

  /**
   * 
   * Returns a promise so we can .then() and .catch() by ourselves
   */
  async getUnitNames(jwt: string|null, units: string[]): Promise<{[s: string]: string}> {
    if(units.length === 0) {
      return {};
    }

    let config: AxiosRequestConfig = {
      headers: {
        'Authorization': `Bearer ${jwt}`,
      }
    }

    if(jwt === null) {
      console.log("No JWT set");
      config = {}; // No auth to provide
    }

    // Very small wrapper to remove some needless stuff from the return value
    try {
      const response = await axios.post(`${apiLocation}/api/v1/units_to_names`, units.join("&"), config);
      return response.data;
    } catch(error) {
      throw error;
    }
  }

  /**
   * 
   * Returns a promise so we can .then() and .catch() by ourselves
   */
  async resetPass(jwt: string|null, pw: string, cur_pw: string): Promise<{error?: string}> {
    let config: AxiosRequestConfig = {
      headers: {
        'Authorization': `Bearer ${jwt}`,
      }
    }

    if(jwt === null) {
      console.log("No JWT set");
      config = {}; // No auth to provide
    }

    // Very small wrapper to remove some needless stuff from the return value
    try {
      const response = await axios.post(`${apiLocation}/api/v1/reset_pass`, `pw=${encodeURIComponent(pw)}&cur_pw=${encodeURIComponent(cur_pw)}`, config);
      return response.data;
    } catch(error) {
      throw error;
    }
  }

  /**
   * Flags should be toggled after using this
   * @param jwt 
   * @param id Mongo id of unit to set
   * @param pos
   */
  async savePos(jwt: string|null, id: string, pos: { x: number, y: number }): Promise<string> {
    let config: AxiosRequestConfig = {
      headers: {
        'Authorization': `Bearer ${jwt}`,
      }
    }

    if(jwt === null) {
      console.log("No JWT set");
      config = {}; // No auth to provide
    }

    // Very small wrapper to remove some needless stuff from the return value
    try {
      const response = await axios.post(`${apiLocation}/api/v1/web_pos/${id}`, `x=${Math.round(pos.x)}&y=${Math.round(pos.y)}`, config);
      // TODO: Status not 200?
      return response.data;
    } catch(error) {
      throw error;
    }
  }

}

export default ApiClient;