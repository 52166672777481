import { Button, Checkbox, Modal } from "antd";
import Select, { LabeledValue } from "antd/lib/select";
import React, { useState } from "react"
import { useHistory } from "react-router-dom";
import { BalancerNodeModel } from "../../diagramming/nodes";
import { useActions, useStore } from "../../store";
import { normalize_key } from "../../util";
const { Option } = Select;

export const VoucherContextMenu = () => {
  const history = useHistory();
  const setVoucherItem = useActions(state => state.api.setVoucherItem);
  const selection = useStore(state => state.diagram.selection);
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [value, setValue] = useState(selection[0] ? (selection[0].props.item ? selection[0].props.item : "") : "");
  const jwt = useStore(state => state.api.jwt);
  const nodes = useStore(state => state.diagram.nodes);
  const model = useStore(state => state.diagram.model);
  const [linkedItems, setLinkedItems] = useState(['']);
  const [asnChecked, setAsnChecked] = useState(true);
  
  if(selection.length === 0) {
    return (<></>);
  }

  const routeChange = (route: string) =>{ 
    history.push(route);
  }

  const onSelect = (value: string | number | LabeledValue, option: any) => {
    if(typeof value !== "string") return;
    setValue(value);
  };

  const onCancel = () => {
    setVisible(false);
    model.setLocked(false);
  };

  const onOk = () => {
    setConfirmLoading(true);
    const vid = selection[0].id;
    setVoucherItem({jwt, id: vid, item: value, as_name: asnChecked && value.length > 0}).finally(() => {
      nodes[vid].props.item = value;
      setVisible(false);
      model.setLocked(false);
      setConfirmLoading(false);
    });
  };

  const showModal = () => {
    setLinkedItems(['']);
    selection[0].links.forEach(link => {
      let node = undefined;
      if(nodes[link.from] && nodes[link.from].type === BalancerNodeModel.NODE_TYPE) {
        node = nodes[link.from];
      } else if(link.to && nodes[link.to] && nodes[link.to].type === BalancerNodeModel.NODE_TYPE) {
        node = nodes[link.to];
      }

      if(node && node.props.items) {
        let newItems = [...linkedItems];
        Object.keys(node.props.items).forEach(item => {
          const nitem = normalize_key(item);
          if(!linkedItems.includes(nitem, 1)) {
            newItems.push(nitem);
          }
        });
        setLinkedItems(newItems);
      }
    });
    setVisible(true);
    model.setLocked(true);
  };

  const onAsnChanged = (e: any) => {
    setAsnChecked(e.target.checked);
  }
  
  return (
    <>
      <Modal
          title="Voucher Item"
          visible={visible}
          onOk={onOk}
          confirmLoading={confirmLoading}
          onCancel={onCancel}
          maskClosable={false}
        >
        <Select
              style={{width: "100%"}}
              showSearch
              placeholder="Type to Search"
              optionFilterProp="key"
              onSelect={onSelect}
              filterSort={(optionA: any, optionB: any) =>
                {
                  if(!optionA.key || optionA.key.length === 0) return 1;
                  if(!optionB.key) return -1;
                  return optionA.key.toLowerCase().localeCompare(optionB.key.toLowerCase());
                }
              }
            >
              {linkedItems.map((item) => (
                <Option disabled={value === item} key={item} value={item}>
                  <span style={{fontFamily: "monospace"}}>{item.length === 0 ? '{Random Roll}' : item}</span>
                </Option>
              ))}
            </Select>
            <Checkbox disabled={value.length === 0} style={{marginLeft: "8px"}} checked={asnChecked && value.length > 0} onChange={onAsnChanged}>Autoset Name to Item</Checkbox>
      </Modal>
      <h1>Voucher Properties</h1>
      <Button type="primary" block onClick={showModal}>
        Set Item...
      </Button>
      <h1>Analytics Properties</h1>
      <Button type="primary" block onClick={() => routeChange(`/analytics?id=${selection[0].id}`)}>
        View Analytics...
      </Button>
    </>
  )
}