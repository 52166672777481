import { Pie } from "@ant-design/charts";
import React, { useRef } from "react";

export interface LabeledPieProps {
  data: {x: string, y: number}[],
  width?: number,
  height?: number,
  loading?: boolean
}

/**
 * Mostly just a wrapper around some stuff I have to do to make things look nice.
 * Non-basic usage might have to branch off
 */
export const LabeledPie = ({ data, width, height, loading } : LabeledPieProps) => {
  let dataCnt = useRef(0);
  let dataTotal = useRef(0);

  return (
    <Pie loading={loading} width={width ?? 450} height={height ?? 350} data={data}
      colorField="x" angleField="y" interactions={[{type:'element-single-selected'}]}
      color={['#74389A', '#8F4376', '#EC9028', '#8934A4', '#3E22B0', '#000062', '#372981', '#53389A']}
      label={{
        type: 'inner',
        offset: 20,
        callback: (y, x) => {
          dataTotal.current += y;
          dataCnt.current += 1;
          if(y/dataTotal.current < 0.019*(""+y).length) {
            if(dataCnt.current === data.length) {
              dataTotal.current = 0;
              dataCnt.current = 0;
            }
            return null;
          }
          if(dataCnt.current === data.length) {
            dataTotal.current = 0;
            dataCnt.current = 0;
          }
          return y;
        },
        autoRotate: false
      }}
      onEvent={(c, e) => {
        // Events fire after label callback
        if(e.type === "legend-item:click") {
          // I need this because the callback will only fire for the number
          // of non-filtered data items, and we will never know that length
          dataTotal.current = 0;
          dataCnt.current = 0;
        }
      }}
    />
  );
};