import createEngine, { DefaultDiagramState, DefaultLinkModel, DiagramModel as ReactDiagramModel } from "@gacha/gacha-diagrams";
import { Action, action } from "easy-peasy";
import { BalancerNodeFactory, DistNodeFactory, GachaNodeModel, GachaNodeFactory, DistNodeModel, BalancerNodeModel, FLAG_DISABLED, FLAG_OWNERONLY, VendorNodeModel, VendorNodeFactory } from "../diagramming/nodes";
import { VoucherNodeFactory, VoucherNodeModel } from "../diagramming/nodes/voucher";
import { ConstrainedPortModel } from "../diagramming/ports";
import { StateNodeModel } from "../diagramming/StateNodeModel";
import { PortType } from "../diagramming/util";
import { Unit } from "../views/editor/EditorView";
import { WheelEvent } from 'react';
import { Action as EngineAction, ActionEvent, InputType } from "@gacha/gacha-diagrams/canvas";

export interface CustomZoomCanvasActionOptions {
	inverseZoom?: boolean;
}

export class CustomZoomCanvasAction extends EngineAction {
	constructor(options: CustomZoomCanvasActionOptions = {}) {
		super({
			type: InputType.MOUSE_WHEEL,
			fire: (actionEvent) => {
				const { event } = actionEvent as ActionEvent<WheelEvent>;
				// we can block layer rendering because we are only targeting the transforms
				for (let layer of this.engine.getModel().getLayers()) {
					layer.allowRepaint(false);
				}

				const model = this.engine.getModel();
				event.stopPropagation();
				const oldZoomFactor = this.engine.getModel().getZoomLevel() / 100;
				let scrollDelta = options.inverseZoom ? -event.deltaY : event.deltaY;
				//check if it is pinch gesture
				if (event.ctrlKey && scrollDelta % 1 !== 0) {
					/*
						Chrome and Firefox sends wheel event with deltaY that
						have fractional part, also `ctrlKey` prop of the event is true
						though ctrl isn't pressed
					*/
					scrollDelta /= 3;
				} else {
					scrollDelta /= 60;
				}
				if (model.getZoomLevel() + scrollDelta > 10) {
					model.setZoomLevel(model.getZoomLevel() + scrollDelta);
				}

				const zoomFactor = model.getZoomLevel() / 100;

				const boundingRect = event.currentTarget.getBoundingClientRect();
				const clientWidth = boundingRect.width;
				const clientHeight = boundingRect.height;
				// compute difference between rect before and after scroll
				const widthDiff = clientWidth * zoomFactor - clientWidth * oldZoomFactor;
				const heightDiff = clientHeight * zoomFactor - clientHeight * oldZoomFactor;
				// compute mouse coords relative to canvas
				const clientX = event.clientX - boundingRect.left;
				const clientY = event.clientY - boundingRect.top;

				// compute width and height increment factor
				const xFactor = (clientX - model.getOffsetX()) / oldZoomFactor / clientWidth;
				const yFactor = (clientY - model.getOffsetY()) / oldZoomFactor / clientHeight;

				model.setOffset(model.getOffsetX() - widthDiff * xFactor, model.getOffsetY() - heightDiff * yFactor);
				this.engine.repaintCanvas();

				// re-enable rendering
				for (let layer of this.engine.getModel().getLayers()) {
					layer.allowRepaint(true);
				}
			}
		});
	}
}

export interface CurrentLink {
  fromType: string,
  fromId: string,
  viewId: string
}

export interface Link {
  /** id of From node */
  from: string;
  /** id of To node */
  to?: string;
  /** viewId of Link */
  viewId?: string;
}

export interface Node {
  type: string;
  name: string;
  id: string;
  viewId?: string;
  links: Link[];
  x: number;
  y: number;
  props: Unit;
}

export interface DiagramModel {
  wipNodes: { [key: string]: Node; };
  /**
   * An object full of nodes keyed by id (not viewId)
   */
  nodes: { [key: string]: Node; };
  selection: Node[];
  currentLink: CurrentLink|null,
  model: ReactDiagramModel;
  engine: ReturnType<typeof createEngine>;
  /**
   * Adding links in createNode in our use cases might fail
   * if a node doesn't exist yet, so create all and then apply links
   */
  createNode: Action<DiagramModel, {
    type: string,
    name: string,
    id: string,
    x: number,
    y: number,
    props: Unit
  }>;
  finalizeNodeUpdate: Action<DiagramModel, {} | undefined>;
  deleteNode: Action<DiagramModel, { id: string }>;
  updateNode: Action<DiagramModel, { id: string, x?: number, y?: number, links?: Link[] }>;
  updateSelection: Action<DiagramModel, { selected: boolean, id: string }>;
  setCurrentLink: Action<DiagramModel, CurrentLink|null>;
  applyLink: Action<DiagramModel, Link>;
  createLink: Action<DiagramModel, { link: Link, fromPortType: PortType, toPortType: PortType}>;
  deleteLink: Action<DiagramModel, string>;
}



export const createDiagramModel = () => {
  const engine = createEngine();
  engine.maxNumberPointsPerLink = 0;
  engine.getActionEventBus().registerAction(new CustomZoomCanvasAction());
  const stateMachine = engine.getStateMachine().getCurrentState();
  if (stateMachine instanceof DefaultDiagramState) {
    stateMachine.dragNewLink.config.allowLooseLinks = false;
    stateMachine.dragNewLink.config.allowLinksFromLockedPorts = false;
  }
  engine.getNodeFactories().registerFactory(new GachaNodeFactory());
  engine.getNodeFactories().registerFactory(new DistNodeFactory());
  engine.getNodeFactories().registerFactory(new BalancerNodeFactory());
  engine.getNodeFactories().registerFactory(new VoucherNodeFactory());
  engine.getNodeFactories().registerFactory(new VendorNodeFactory());
  const model = new ReactDiagramModel();
  engine.setModel(model);
  
  return {
    wipNodes: {},
    nodes: {},
    engine,
    model,
    selection: [],
    currentLink: null,
    /** 
     * Attempts to create a visual and local node.
     * If the node already exists, update properties
     */
    createNode: action((state, payload) => {
      const has = state.nodes[payload.id];
      let node: Node = {...payload, links: []};
      if (has) {
        let newFlags = node.props.flags;
        if(newFlags && has.props.flags) {
          // Clear disabled and owneronly
          newFlags &= ~(FLAG_DISABLED | FLAG_OWNERONLY);
          // Keep local state of disabled/owneronly
          newFlags |= has.props.flags & (FLAG_DISABLED | FLAG_OWNERONLY);
          node.props.flags = newFlags;
        }
        if(has.name !== node.name || has.props.seen !== node.props.seen || has.props.flags !== newFlags) {
          state.wipNodes[node.id] = node;
          state.wipNodes[node.id].links = has.links;
          state.wipNodes[node.id].viewId = has.viewId;
        }
        return;
      }
      let nodeModel: StateNodeModel;
      if (payload.type === GachaNodeModel.NODE_TYPE) {
        nodeModel = new GachaNodeModel({
          stateId: payload.id
        });
      } else if (payload.type === DistNodeModel.NODE_TYPE) {
        nodeModel = new DistNodeModel({
          stateId: payload.id
        });
      } else if (payload.type === BalancerNodeModel.NODE_TYPE) {
        nodeModel = new BalancerNodeModel({
          stateId: payload.id
        });
      } else if (payload.type === VoucherNodeModel.NODE_TYPE) {
        nodeModel = new VoucherNodeModel({
          stateId: payload.id
        });
      } else if (payload.type === VendorNodeModel.NODE_TYPE) {
        nodeModel = new VendorNodeModel({
          stateId: payload.id
        });
      } else {
        return;
      }
    
      nodeModel.setPosition(node.x, node.y);
      node.viewId = nodeModel.getID();
      state.model.addNode(nodeModel);
      state.wipNodes[node.id] = node;
    }),
    finalizeNodeUpdate: action((state, payload) => {
      state.nodes = {...state.nodes, ...state.wipNodes};
      if(state.selection.length > 0) {
        const node = state.nodes[state.selection[0].id];
        state.selection[0].props.seen = node.props.seen;
        state.selection[0].props.flags = node.props.flags;
      }
      state.wipNodes = {};
    }),
    deleteNode: action((state, payload) => {
      state.selection = state.selection.filter(s => s.id !== payload.id);
      delete state.nodes[payload.id];
      // TODO: Delete links and model node
    }),
    updateNode: action((state, payload) => {
      let { id, ...properties } = payload;
      const node = state.nodes[id];
      if (!node) {
        return;
      }
      Object.entries(properties)
        .filter(([key, value]) => value !== undefined)
        .forEach(([key, value]) => (node as any)[key] = value);
    }),
    updateSelection: action((state, payload) => {
      const node = state.nodes[payload.id];
      if (!node) {
        return;
      }
      if (payload.selected) {
        state.selection.push(node);
      }
      else {
        state.selection = state.selection.filter(node => node.id !== payload.id);
      }
    }),
    setCurrentLink: action((state, payload) => {
      state.currentLink = payload;
    }),
    /** Update our local state when linking hooks fire to completion */
    applyLink: action((state, payload) => {
      const { from, to, viewId } = payload;
      if(!to) return;
      const stateFrom = state.nodes[from];
      const stateTo = state.nodes[to];
      if (!stateFrom || !stateTo) {
        return;
      }
      
      // We also want to update selection[0] because that will allow context menus to update live
      if(!stateFrom.links.some(link => link.from === from && link.to === to && link.viewId === viewId)) {
        stateFrom.links.push(payload);
        if(state.selection.length > 0 && state.selection[0].id === from) {
          state.selection[0].links.push(payload);
        }
      }
      if(!stateTo.links.some(link => link.from === to && link.to === from && link.viewId === viewId)) {
        stateTo.links.push({from: to, to: from, viewId: viewId});
        if(state.selection.length > 0 && state.selection[0].id === to) {
          state.selection[0].links.push({from: to, to: from, viewId: viewId});
        }
      }
    }),
    /** Create a visual link between two ports. */
    createLink: action((state, payload) => {
      if(!payload.link.to) return;
      let stateFrom = state.nodes[payload.link.from];
      let stateTo = state.nodes[payload.link.to];

      if (!stateFrom || !stateTo) {
        return;
      }

      if(!stateFrom.viewId || !stateTo.viewId) return;

      const modelFrom = state.model.getNode(stateFrom.viewId);
      const modelTo = state.model.getNode(stateTo.viewId);
      
      if(!modelFrom || !modelTo) return;

      const fromPort = modelFrom.getPort(payload.fromPortType) as ConstrainedPortModel;
      const toPort = modelTo.getPort(payload.toPortType);

      if(!fromPort || !toPort) return;

      if(!fromPort.canLinkToPort(toPort))
        return;
      
      let linkModel = new DefaultLinkModel();
      linkModel.setSourcePort(fromPort);
      linkModel.setTargetPort(toPort);

      // It may look like it works without setting these points, but it will
      // go to 0,0 if it regenerates a deleted link and the nodes moved
      linkModel.setPoints([linkModel.generatePoint(fromPort.getX() + fromPort.width / 2, fromPort.getY() + fromPort.height / 2), linkModel.generatePoint(toPort.getX() + toPort.width / 2, toPort.getY() + toPort.height / 2)]);
      
      state.model.getActiveLinkLayer().addModel(linkModel);
      
      const viewId = linkModel.getID();
      if (!stateFrom.links.some(link => link.from === stateFrom.id && link.to === stateTo.id && link.viewId === viewId)) {
        stateFrom.links.push({from: stateFrom.id, to: stateTo.id, viewId: viewId});
      }
      if (!stateTo.links.some(link => link.from === stateTo.id && link.to === stateFrom.id && link.viewId === viewId)) {
        stateTo.links.push({from: stateTo.id, to: stateFrom.id, viewId: viewId});
      }
      // Don't fire an event (It gets stuck somewhere in the react-diagram code?)
    }),
    deleteLink: action((state, payload) => {
      Object.values(state.nodes).forEach(node => {
        const filtered = node.links.filter(link => link.viewId !== payload);
        if (filtered.length !== node.links.length) {
          node.links = filtered;
        }
      })
    })
  } as DiagramModel;
}

export default createDiagramModel;