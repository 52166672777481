import React, { useState } from 'react';
import styled from 'styled-components';
import { useActions, useStore } from '../../store';
import gridBg from '../../assets/editor_bg.png';
import smLogo from './img/tetra-logo-nt-64.png';
import  { Redirect } from 'react-router-dom'
import { Spin, Form, Input, Button, Alert } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import './LoginView.css';

export const LoginView = () => {
  const loginError = useStore(state => state.api.loginError);
  const isLoggedIn = useStore(state => state.api.jwt !== null);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loggingIn, setLoggingIn] = useState(false);
  const login = useActions(actions => actions.api.login);

  if(isLoggedIn) {
    if(loggingIn)
      setLoggingIn(false);
    return (
      <Redirect to='/'/>
    );
  }

  if(typeof loginError === "string") {
    console.log(`Login error: ${loginError}`);
    if(loggingIn)
      setLoggingIn(false);
  }

  if(loggingIn) {
    // Loading spinner
    return (
      <ViewContainer>
        <LoginContainer>
          <Spin />
          <LogoSmall />
        </LoginContainer>
      </ViewContainer>
    );
  }

  function validateForm() {
    return username.length > 0 && password.length > 0;
  }

  const onFinish = (values: {username: string, password: string}) => {
    setLoggingIn(true);
    login({username: values.username, password: values.password});
  };

  const onValuesChange = (values: {username: string, password: string}) => {
    if(typeof values.username === "string")
      setUsername(values.username);
    if(typeof values.password === "string")
      setPassword(values.password);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <ViewContainer>
      <LoginContainer>
        <Form
        name="basic"
        className="login-form"
        initialValues={{
          remember: true,
          username: username,
          password: password
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        onValuesChange={onValuesChange}
      >
        <Alert className="login-form-error" style={{display: typeof loginError === "string" ? "" : "none"}} message={loginError} type="error" showIcon />
        <Break />
        <Form.Item
          name="username"
          rules={[
            {
              required: true,
              message: 'Please input your username!',
            },
          ]}
        >
          <Input placeholder="Username" prefix={<UserOutlined className="site-form-item-icon" />} />
        </Form.Item>
        <Break />
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: 'Please input your password!',
            },
          ]}
        >
          <Input.Password placeholder="Password" prefix={<LockOutlined className="site-form-item-icon" />} />
        </Form.Item>

        <Form.Item className="login-form-button-container">
        <Button type="primary" htmlType="submit" disabled={!validateForm()} className="login-form-button">
          Login
        </Button>
      </Form.Item>
      </Form>
      <LogoSmall />
      </LoginContainer>
    </ViewContainer>
  );
};

const ViewContainer = styled.div`
  width: 100%;
  height: 100%;
  background: url('${gridBg}');
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoginContainer = styled.div`
  width: 450px;
  height: 300px;
  background: #fdfdfd;
  border-radius: 24px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 5px 10px 12px 12px #00000033;
`;

const LogoSmall = styled.div`
  background: url('${smLogo}');
  background-repeat: no-repeat;
  background-size: contain;
  bottom: 24px;
  /*right: 10px;*/
  width: 30px;
  height: 30px;
  position: absolute;
  display: block;
`;

const Break = styled.div`
  flex-basis: 100%;
`;

export default LoginView;