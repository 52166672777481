import React from 'react';
import styled from 'styled-components';
import lockedChestIcon from './svg/locked-chest.svg';
import scalesIcon from './svg/scales.svg';
import twoCoinsIcon from './svg/two-coins.svg';
import ticketIcon from './svg/ticket.svg';

const iconNameMap = {
  'gacha-node': lockedChestIcon,
  'balancer-node': scalesIcon,
  'distributor-node': twoCoinsIcon,
  'voucher-node': ticketIcon
}

export type IconNames = keyof typeof iconNameMap;

export interface IconProps {
  name: IconNames;
  style?: React.CSSProperties;
}

export const Icon = ({ name, ...props }: IconProps) => {
  return (
    <IconContainer 
      style={{
        backgroundImage: `url('${iconNameMap[name]}')`,
        ...(props.style ?? {})
      }}
      {...props} />
  )
}

const IconContainer = styled.div`
  width: 1rem;
  height: 1rem;
  background-repeat: no-repeat;
`;