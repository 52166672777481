import { Area } from "@ant-design/charts";
import { Button, Card } from "antd";
import React, { useState } from "react";

export interface MultiAreaProps {
  datas: [{}[], {}[]],
  width?: number,
  height?: number,
  loading?: boolean,
  titles?: [string, string],
  types: [string, string],
  xFields: [string, string],
  yFields: [string, string],
  sliders: [any, any],
  style?: React.CSSProperties
}

/**
 * Mostly just a wrapper around some stuff I have to do to make things look nice.
 * Non-basic usage might have to branch off
 */
export const MultiAreaCard = ({ datas, width, height, loading, types, titles, xFields, yFields, sliders, style } : MultiAreaProps) => {
  const [type, setType] = useState(true);

  const togType = () => {
    setType(!type);
  };

  return (
    type ?

      <Card title={titles ? titles[0] : undefined} style={style} extra={(
        <Button type="link" onClick={togType}>{types[1]}</Button>
      )}>
        <Area loading={datas[0].length === 0 || loading} xField={xFields[0]} yField={yFields[0]} width={900} height={350}
          data={datas[0]}
          slider={sliders[0]}
        />
      </Card>

      :

      <Card title={titles ? titles[1] : undefined} style={style} extra={(
        <Button type="link" onClick={togType}>{types[0]}</Button>
      )}>
        <Area loading={datas[1].length === 0 || loading} xField={xFields[1]} yField={yFields[1]} width={900} height={350}
          data={datas[1]}
          slider={sliders[1]}
        />
      </Card>
  );
};