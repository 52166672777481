import { Card, Table } from "antd";
import React, { MutableRefObject } from "react";
import { Transaction } from "../AnalyticsView";

export interface EntryProps {
    analytics: Transaction[],
    unitNameCache: {[s: string]: string} | null,
    initialized: MutableRefObject<number>,
    style?: React.CSSProperties
}

export const EntriesDash = ({ analytics, unitNameCache, initialized, style } : EntryProps) => {

    const columns = [
        {
            title: 'User',
            key: 'user',
            dataIndex: 'uname',
            width: '10%'
        },
        {
            title: 'Item',
            key: 'item',
            dataIndex: 'item',
            width: '29%'
        },
        {
            title: 'Price',
            key: 'price',
            dataIndex: 'price',
            width: '6%',
            render: (value: any) => {
                return "L$" + value;
            }
        },
        {
            title: 'Date',
            key: 'date',
            dataIndex: 'time',
            width: '10%',
            render: (value: any) => {
                return new Date(value*1000).toLocaleString().replace(',', '');
            }
        },
        {
            title: 'Region',
            key: 'region',
            dataIndex: 'region',
            width: '12%'
        },
        {
            title: 'Unit',
            key: 'unit',
            dataIndex: 'unit',
            width: '18%',
            render: (value: any) => {
                return (<a href={`analytics?id=${value.$oid}`}>{`${((unitNameCache ?? {})[value.$oid] ?? "Unknown")} (${value.$oid.substring(value.$oid.length-7).toUpperCase()})`}</a>);
            }
        },
        {
            title: 'Refund',
            key: 'refund',
            dataIndex: 'refund',
            width: '7%',
            render: (value: any) => {
                switch(value) {
                    case 0:
                        return "Failure";
                    case 1:
                        return "Success";
                    case 2:
                        return "Pending";
                    default:
                        return "No";
                }
            }
        },
        {
            title: 'Type',
            key: 'type',
            dataIndex: 'type',
            width: '7%',
            render: (value: any) => {
                switch(value) {
                    case 0:
                        return "Gacha Play";
                    case 1:
                        return "Voucher";
                    case 1000:
                        return "Push Redelivery";
                    case 1001:
                        return "Self Redelivery";
                    default:
                        return "Unknown";
                }
            }
        },
    ];

    return (
        <>
            <Card title={`All Entries (${analytics.length})`} style={{width: "100%", margin: "20px 0", ...style}}>
            <Table loading={initialized.current < 3}
                bordered
                dataSource={analytics.sort((a, b) => b.time - a.time)}
                columns={columns}
            />
            </Card>
        </>
    );
};