import { NodeModel } from "@gacha/gacha-diagrams";
import { StateModelOptions } from "./nodes";

export class StateNodeModel extends NodeModel {
  stateOptions: StateModelOptions;

  constructor(options: StateModelOptions) {
    super({
      ...options
    });
    this.stateOptions = options;

  }

  serialize() {
    return {
      ...super.serialize(),
      stateOptions: this.stateOptions
    }
  }

  deserialize(event: any): void {
    super.deserialize(event);
    this.stateOptions = event.data.stateOptions;
  }

  getStateId(): string {
    return this.stateOptions.stateId;
  }
}