export function parseJwt(token: string | null): any {
  if(!token) return null;
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
};

export function normalize_key(k: string) {
  return k.replace(/\|S\|/g, "$").replace(/\|,\|/g, ".");
}

export function sanitize_key(k: string) {
  return k.replace(/\$/g, "|S|").replace(/\./g, "|,|");
}

export const isChrome = !!(window as { [key: string]: any })["chrome"] && (!!(window as { [key: string]: any })["chrome"].webstore || !!(window as { [key: string]: any })["chrome"].runtime);