import { DeleteFilled } from "@ant-design/icons";
import { Alert, Button, Form, Input, Modal, Popconfirm, Table } from "antd"
import { AxiosError } from "axios";
import React, { useState } from "react"
import { useActions, useStore } from "../../store";
import { parseJwt } from "../../util";

type KeysModalProps = {
  visible: boolean,
  aliases: { [s: string]: string },
  onOk: (aliases: { [s: string]: string }) => void
}

export const KeysModal = ({ visible, aliases, onOk }: KeysModalProps) => {
  const jwt = useStore(state => state.api.jwt);
  let djwt = parseJwt(jwt);

  const setAlias = useActions(state => state.api.setUuidAlias);
  const [localAliases, setLocalAliases] = useState<{ key: string, alias: string }[]>(
    Object.entries(aliases).map(
      (item) => {
        return { key: item[0], alias: item[1] };
      }
    )
  );
  const [wasRendered, setWasRendered] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined);

  const v4 = new RegExp(/^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i);
  const checkUuid = (rule: any, value: string) => {
    if(v4.test(value) && value !== djwt.uid) {
      return Promise.resolve();
    } else if(value === djwt.uid) {
      return Promise.reject("You cannot enter your own key!");
    }
    return Promise.reject("Please enter a valid key!");
  };

  const ascii = new RegExp(/^[-@&%_+()#?0-9A-Z .]{1,16}$/i);
  const checkAscii = (rule: any, value: string) => {
    if(ascii.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject("Please enter a valid alias!");
  };

  const handleDelete = (uuid: string) => {
    // TODO: Send removal
    setLocalAliases(localAliases.filter(item => item.key !== uuid));
  };

  const onAdd = (values: { uuid: string, alias: string | undefined }) => {
    setAddLoading(true);
    let aliases: { [s: string]: string } = {};
    aliases[values.uuid] = values.alias ? values.alias : " ";
    setAlias({jwt, aliases}).then((response: number) => {
      if(response > 0) {
        let updated = [...localAliases];
        const insert = { key: values.uuid, alias: values.alias ? values.alias : " " };
        const find = updated.findIndex(v => v.key === values.uuid);
        if(find >= 0)
          updated[find] = insert;
        else
          updated.push(insert);
        setLocalAliases(updated);

      } else {
        setErrorMsg("Failed to add alias. Please try again later.");
      }
    }).catch((err: AxiosError) => {
      setErrorMsg("Failed to add alias. Please try again later.");
    }).finally(() => {
      setAddLoading(false);
    });
  };

  const columns = [
    {
      title: 'Key',
      dataIndex: 'key',
      width: '41%',
      sorter: true,
      //editable: true,
    },
    {
      title: 'Alias',
      dataIndex: 'alias',
      width: '40%',
      sorter: true,
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (text: any, record: { key: string, alias: string }) =>
        localAliases.length >= 1 ? (
          <Popconfirm title="Are you sure?" onConfirm={() => handleDelete(record.key)}>
            <Button type="primary" danger shape="circle" size="small" style={{marginLeft: "36px", width: "24px"}}><DeleteFilled className="deleteBtnIcon" /></Button>
          </Popconfirm>
        ) : null,
    },
  ];

  if(!visible) {
    if(localAliases.length > 0) {
      setLocalAliases([]);
      setWasRendered(false);
    }
    return (<></>);
  } else if(localAliases.length === 0 && !wasRendered) {
    setWasRendered(true);
    setLocalAliases(
      Object.entries(aliases).map(
        (item) => {
          return { key: item[0], alias: item[1] };
        }
      )
    );
  }
            // TODO: Trash and pencil icon instead of x and +
  return (
    <>
      <Modal
          title="Keys"
          visible={visible}
          onOk={() => onOk(Object.fromEntries(localAliases.map((value) => [value.key, value.alias])))}
          closable={false}
          maskClosable={false}
          width="720px"
          mask={false}
          cancelButtonProps={{ style: { display: "none" } }}
        >
          <Alert className="drop-form-error" style={{display: typeof errorMsg === "string" ? "" : "none"}} message={errorMsg} type="error" showIcon />
          
          <Form layout="inline" style={{ height: "60px" }} onFinish={onAdd}>
            <Form.Item name="uuid" rules={[{ validator: checkUuid }]}>
              <Input placeholder="00000000-0000-4000-a000-000000000000" style={{ width: "21em" }} maxLength={36} autoComplete="off" />
            </Form.Item>
            <Form.Item name="alias" normalize={(value: string) => (value || '').toLowerCase()} rules={[{ validator: checkAscii }]}>
              <Input placeholder="Alias (Optional)" style={{ width: "14em" }} maxLength={16} autoComplete="off" />
            </Form.Item>
            <Form.Item name="submit">
              <Button type="primary" loading={addLoading} htmlType="submit">Add</Button>
            </Form.Item>
          </Form>

          <Table
            bordered
            dataSource={localAliases}
            columns={columns}
            rowClassName={() => 'alias-row'}
          />

      </Modal>
    </>
  )
}