
const items = {
  editor: {
    BACKGROUND_SIZE_PX: 210 // affects zoom
  }
}

export function getConfig() {
  return items;
}

export default getConfig;