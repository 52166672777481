import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { DiagramEngine, DiagramModel } from '@gacha/gacha-diagrams';
import { CanvasWidget } from '@gacha/gacha-diagrams/canvas';
import { useActions, useStore } from '../../store';
import gridBg from '../../assets/editor_bg.png';
import { useEngineModelStateListeners } from '../../diagramming/util/hooks';
import { Transition } from 'react-transition-group';
import ContextMenu from '../../components/ContextMenu';
import { StatusBar } from '../../components/StatusBar';
import getConfig from '../../config';
import { Redirect } from 'react-router-dom';
import { PortType } from '../../diagramming/util';
import { VoucherNodeModel } from '../../diagramming/nodes/voucher';
import { BalancerNodeModel, DistNodeModel, GachaNodeModel, VendorNodeModel } from '../../diagramming/nodes';
import { AxiosError } from 'axios';
import Modal from 'antd/lib/modal/Modal';
import { Alert, Form, Input } from 'antd';
import { LockOutlined } from '@ant-design/icons';

interface MongoRef {
  $oid: string
}

export interface Unit {
  /** Mongo ID of the unit */
  _id: MongoRef,
  /** Items stored in unit (Gacha or balancer)
   * 
   * Items are stored in a json object with their name as the key,
   * and some fields:
   * 
   * pot1: {perm: 532480, chance: 1}
   */
  items?: { [s: string]: {perm: number, chance: number, tex?: string, price?: number}; },
  /** Balancers attached to gacha unit */
  bals?: MongoRef[],
  /** Seconds unix epoch of last ping */
  seen: number,
  /** Pos in region the unit is in (Not on vouchers) */
  pos?: {x: number, y: number, z: number},
  /** Region the unit is in (Not on vouchers) */
  region?: string,
  /** Type of the unit
   * 
   * Gacha = 0,
   * Distributor = 1,
   * Balancer = 2,
   * Voucher = 3,
   */
  type: number,
  /** Internal settings flags of the unit (Generally gacha) */
  flags?: number,
  /** Price linked to unit */
  price?: number,
  /** Item linked to voucher unit */
  item?: string,
  /** Name of object (For vouchers this is more representative of the title text) */
  name?: string,
  /** Shares linked to distributor unit */
  shares?: {_id: string, share: number}[],
  /** Distributor attached to gacha unit */
  dist?: MongoRef,
  /** Position for the node on the webpage */
  webpos?: {x: number, y: number}
}

const config = getConfig();

const [startOffsetX, startOffsetY] = [0, 0];

export const EditorView = () => {
  const engine = useStore(store => store.diagram.engine) as DiagramEngine;
  const model = useStore(store => store.diagram.model) as DiagramModel;
  const contextMenuOpen = useStore(store => store.editor.contextMenu.open);
  const zoom = useStore(state => state.editor.zoom.value);
  const jwt = useStore(state => state.api.jwt);
  const shouldReset = useStore(state => state.api.shouldReset);
  const resetPass = useActions(actions => actions.api.resetPass);
  const setJwt = useActions(actions => actions.api.setJWT);
  const setZoom = useActions(actions => actions.editor.zoom.set);
  const getUnits = useActions(actions => actions.api.getUnits);
  const createNode = useActions(actions => actions.diagram.createNode);
  const finalizeNodeUpdate = useActions(actions => actions.diagram.finalizeNodeUpdate);
  const createLink = useActions(actions => actions.diagram.createLink);
  const [[offsetX, offsetY], setOffset] = useState([startOffsetX, startOffsetY]);
  const [initializedNodes, setInitializedNodes] = useState(0);
  const [resetPassLoading, setResetPassLoading] = useState(false);
  const [passErr, setPassErr] = useState<string | null>(null);
  const [resetForm] = Form.useForm();
  const [showHelp, setShowHelp] = useState(false);
  
  useEffect(() => {
    const handle = model.registerListener({
      eventDidFire: (e: any) => {
        if (e.function === 'offsetUpdated') {
          setOffset([e.offsetX, e.offsetY]);
        } else if (e.function === 'zoomUpdated') {
          setZoom((e.zoom / 100) * config.editor.BACKGROUND_SIZE_PX);
        }
      }
    } as any);

    return () => void model.deregisterListener(handle);
  });

  useEffect(() => {
    const attemptGet = () => getUnits(jwt).then((units: Unit[]) => {
      setShowHelp(units.length <= 1);
      setInitializedNodes(initializedNodes + 1);
      console.log("attemptGet");
      let i = 0;
      for(let unit of units) {
        /*
         Gacha = 0,
         Distributor = 1,
         Balancer = 2,
         Voucher = 3,
         Vendor = 4,
         */
        switch(unit.type) {
          /*case 0:
            createNode({
              type: GachaNodeModel.NODE_TYPE,
              name: `${unit.name}`,
              id: unit._id.$oid,
              x: unit.webpos ? unit.webpos.x : 200 * (i % 6) + 50 + -startOffsetX,
              y: unit.webpos ? unit.webpos.y : 210 * Math.floor(i / 6) + 50 + -startOffsetY,
              props: unit
            });
          break;*/
          case 1:
            createNode({
              type: DistNodeModel.NODE_TYPE,
              name: `${unit.name}`,
              id: unit._id.$oid,
              x: unit.webpos ? unit.webpos.x : 200 * (i % 6) + 50 + -startOffsetX,
              y: unit.webpos ? unit.webpos.y : 210 * Math.floor(i / 6) + 50 + -startOffsetY,
              props: unit
            });
          break;
          case 2:
            createNode({
              type: BalancerNodeModel.NODE_TYPE,
              name: `${unit.name}`,
              id: unit._id.$oid,
              x: unit.webpos ? unit.webpos.x : 200 * (i % 6) + 50 + -startOffsetX,
              y: unit.webpos ? unit.webpos.y : 210 * Math.floor(i / 6) + 50 + -startOffsetY,
              props: unit
            });
          break;
          case 3:
            createNode({
              type: VoucherNodeModel.NODE_TYPE,
              name: `${unit.name}`,
              id: unit._id.$oid,
              x: unit.webpos ? unit.webpos.x : 200 * (i % 6) + 50 + -startOffsetX,
              y: unit.webpos ? unit.webpos.y : 210 * Math.floor(i / 6) + 50 + -startOffsetY,
              props: unit
            });
          break;
          case 4:
            createNode({
              type: VendorNodeModel.NODE_TYPE,
              name: `${unit.name}`,
              id: unit._id.$oid,
              x: unit.webpos ? unit.webpos.x : 200 * (i % 6) + 50 + -startOffsetX,
              y: unit.webpos ? unit.webpos.y : 210 * Math.floor(i / 6) + 50 + -startOffsetY,
              props: unit
            });
          break;
        }
        if(!unit.webpos) {
          i++;
        }
      }
      finalizeNodeUpdate(undefined);
      for(let unit of units) {
        if(unit.type !== 0 && unit.type !== 3 && unit.type !== 4) continue;
        const incPort = unit.type === 0 || unit.type === 4 ? PortType.Gacha : PortType.Voucher;
        if(unit.dist) {
          // We know they exist but state.diagram.nodes would lie to us
          let link = {
            from: unit._id.$oid,
            to: unit.dist?.$oid,
          };
          
          createLink({link, fromPortType: PortType.Distributor, toPortType: incPort});
        }
        if(unit.bals) {
          for(let bal of unit.bals) {
            // We know they exist but state.diagram.nodes would lie to us
            let link = {
              from: unit._id.$oid,
              to: bal.$oid,
            };
            
            createLink({link, fromPortType: PortType.Balancer, toPortType: incPort});
          }
        }
      }

      /*if(initializedNodes === 0) {
        setTimeout(() => {
          let dagre = new DagreEngine({
            graph: {
              rankdir: 'LR',
              marginx: -startOffsetX + 50,
              marginy: -startOffsetY + 50,
              nodesep: 0,
              ranksep: 40
            },
            includeLinks: true
          });
      
          dagre.redistribute(model);
          engine.repaintCanvas();
        }, 50);
      }*/
    }).catch((error: AxiosError) => {
      // TODO: Nice looking "Disconnected" header thing
      if(error.response) {
        if(error.response.status === 401) {
          setJwt(null);
        }
      }
      console.log("Disconnected");
    });
    
    if(initializedNodes === 0) {
      // Populate immediately
      model.setOffset(startOffsetX, startOffsetY);
      if(jwt)
        attemptGet();
    }

    const intervalId = setInterval(() => {
      attemptGet();
    }, 8000);
    return () => clearInterval(intervalId);
  }, [createLink, createNode, finalizeNodeUpdate, getUnits, initializedNodes, jwt, model, setJwt, engine, setShowHelp]);

  useEngineModelStateListeners(engine);

  if(!jwt) {
    return <Redirect to='/login'/>
  }

  const onResetPass = (values: { curPassword: string, newPassword: string, newPassword2: string }) => {
    if(values.newPassword !== values.newPassword2) {
      setPassErr("New passwords do not match! Please try again.");
      return;
    }
    setResetPassLoading(true);
    resetPass({jwt, pw: values.newPassword, cur_pw: values.curPassword}).then((response: {error?: string}) => {
        setPassErr("");
    }).catch((error: AxiosError) => {
      if(error.response && error.response.data.error) {
        switch(error.response.data.error) {
          case "Password does not meet requirements":
            setPassErr("Password length must be at least 8 characters.");
            break;
          case "Invalid current password":
            setPassErr("Incorrect current password.");
            break;
          default:
            setPassErr("Something went wrong. Please try again later. (2)");
        }
      } else {
        setPassErr("Something went wrong. Please try again later. (3)");
        console.error(error.response);
      }
    }).finally(() => {
      setResetPassLoading(false);
    });
  };

  const passRuleValidator = (rule: any, value: string) => {
    if(value.length >= 8) {
      return Promise.resolve();
    }
    return Promise.reject("Must be at least 8 characters!");
  };

  let alert = (<></>);

  if(showHelp) {
    alert = (
      <Alert message="Confused on where to start? We have guides on the Wiki to help you!"
        type="info"
        style={{margin: "10px", position: "absolute", zIndex: 30}}
        showIcon
        closable
        action={
          <a target="_blank" rel="noopener noreferrer" href="http://wiki.tetra.network/">
            Visit the Wiki
          </a>
        }
      />
    );
    
  } else {
    alert = (
      <Alert message="Gachas have come to an end and were removed on Aug 30th. But don't fret! We're working on valid systems to replace this. Limitations spawn creativity, as they say..."
        type="info"
        style={{margin: "10px", position: "absolute", zIndex: 30}}
        showIcon
        closable
        action={
          <a target="_blank" rel="noopener noreferrer" href="https://community.secondlife.com/blogs/entry/8586-policy-change-regarding-gacha/">
            View the News
          </a>
        }
      />
    );
  }

  return (
    <ViewContainer style={{
      backgroundPositionX: offsetX,
      backgroundPositionY: offsetY,
      backgroundSize: zoom
    }}>
      {alert}
      <Modal visible={shouldReset} closable={false} maskClosable={false} confirmLoading={resetPassLoading}
        cancelButtonProps={{ style: { display: "none" } }} onOk={resetForm.submit}>
        You currently have a temporary password, and should change it to a password you can actually
        remember. Please do so via the form below.
        <Form form={resetForm} onFinish={onResetPass} style={{marginTop: "20px"}}>
          <Alert style={{display: typeof passErr === "string" ? "" : "none", marginBottom: "20px"}} message={passErr} type="error" showIcon />
          
          <Form.Item
            name="curPassword"
            rules={[
              {
                required: true,
                message: 'Please input current password!',
              },
            ]}
          >
            <Input.Password placeholder="Current Password" prefix={<LockOutlined className="site-form-item-icon" />} />
          </Form.Item>
          <Form.Item
            name="newPassword"
            rules={[
              {
                required: true,
                message: 'Please input a new password!',
                validator: passRuleValidator
              },
            ]}
          >
            <Input.Password placeholder="New Password" prefix={<LockOutlined className="site-form-item-icon" />} />
          </Form.Item>
          <Form.Item
            name="newPassword2"
            rules={[
              {
                required: true,
                message: 'Please repeat the new password!',
              },
            ]}
          >
            <Input.Password placeholder="New Password Again" prefix={<LockOutlined className="site-form-item-icon" />} />
          </Form.Item>
        </Form>
      </Modal>
      <GridCanvas engine={engine} className="canvas" />
        <MainMenuContainer>
          {/* <MainMenu /> */}
        </MainMenuContainer>
        <Transition in={contextMenuOpen} timeout={100}>
          {(state) => (
            <ContextMenuContainer state={state}>
              <ContextMenu />
            </ContextMenuContainer>
          )}
        </Transition>
        <StatusBarContainer>
          <StatusBar />
        </StatusBarContainer>
    </ViewContainer>
  );
};

const ViewContainer = styled.div`
  width: 100%;
  height: 100%;
  background: url('${gridBg}');
  position: relative;
`;

const GridCanvas = styled(CanvasWidget)`
  width: 100%;
  height: 100%;
`;

interface ContextMenuContainerProps { state: 'entering'|'entered'|'exiting'|'exited'|'unmounted' }
const ContextMenuContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 15rem;
  box-sizing: border-box;
  transition: 150ms ease;
  /* opacity: ${({ state }: ContextMenuContainerProps) => ['entering','entered'].includes(state) ? 1 : 0}; */
  transform: translateX(
    ${({ state }: ContextMenuContainerProps) => ['entering','entered'].includes(state) ? '0' : 'calc(100% - 5rem)'}
  );
`;

const StatusBarContainer = styled.div`
  position: fixed;
  bottom: 0;
`;

const MainMenuContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  padding: 1rem;
`;

export default EditorView;