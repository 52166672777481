import { action, Action, thunk, Thunk } from "easy-peasy";
import { Injections } from "../store";

export interface ApiModel {
  jwt: string|null,
  shouldReset: boolean,
  setShouldReset: Action<ApiModel, boolean>,
  setJWT: Action<ApiModel, string|null>,

  loginError: string|null,
  login: Thunk<ApiModel, {username: string, password: string}, Injections>,
  setLoginError: Action<ApiModel, string|null>,

  /**
   * Payload is JWT
   */
  getUnits: Thunk<ApiModel, string|null, Injections>,

  /**
   * See underlying implementation for more info on payload
   */
  attach: Thunk<ApiModel, {jwt: string|null, gachaOrVoucher: string, other: string, otherType: number}, Injections>,

  /**
   * See underlying implementation for more info on payload
   */
  detach: Thunk<ApiModel, {jwt: string|null, gachaOrVoucher: string, other: string, otherType: number}, Injections>,

  droprates: Thunk<ApiModel, {jwt: string|null, unit: string, chances: { [s: string]: number; }}, Injections>,

  vendset: Thunk<ApiModel, {jwt: string|null, unit: string, settings: { [s: string]: { tex?: string, price?: number }; }}, Injections>,

  pushRedelivery: Thunk<ApiModel, {jwt: string|null, unit: string, oldItem: string, newItem?: string, fnc?: boolean}, Injections>,

  shares: Thunk<ApiModel, {jwt: string|null, unit: string, shares: { _id: string, share: number }[]}, Injections>,

  deleteUnit: Thunk<ApiModel, {jwt: string|null, id: string}, Injections>,

  setPrice: Thunk<ApiModel, {jwt: string|null, id: string, price: number}, Injections>,

  setUuidAlias: Thunk<ApiModel, {jwt: string|null, aliases: { [s: string]: string; }}, Injections>,

  getUuidAlias: Thunk<ApiModel, {jwt: string|null, uuids: string[]}, Injections>,

  getAllUuidAlias: Thunk<ApiModel, {jwt: string|null}, Injections>,

  setVoucherItem: Thunk<ApiModel, {jwt: string|null, id: string, item: string, as_name: boolean}, Injections>,

  getRedeliveries: Thunk<ApiModel, {jwt: string|null}, Injections>,

  redeliverItem: Thunk<ApiModel, {jwt: string|null, item: string}, Injections>,

  toggleOwnerOnly: Thunk<ApiModel, {jwt: string|null, id: string, enabled: boolean}, Injections>,

  toggleDisabled: Thunk<ApiModel, {jwt: string|null, id: string, enabled: boolean}, Injections>,

  getAnalytics: Thunk<ApiModel, string|null, Injections>,

  getUnitNames: Thunk<ApiModel, {jwt: string|null, units: string[]}, Injections>,

  resetPass: Thunk<ApiModel, {jwt: string|null, pw: string, cur_pw: string}, Injections>,

  savePos: Thunk<ApiModel, {jwt: string|null, id: string, pos: { x: number, y: number }}, Injections>,
}

export const createApiModel = () => {
  return {
    jwt: localStorage.getItem("jwt"),
    setJWT: action((state, payload) => {
      if(payload !== null) {
        localStorage.setItem("jwt", payload);
      } else {
        localStorage.removeItem("jwt");
      }
      state.jwt = payload;
    }),

    shouldReset: (localStorage.getItem("shouldReset") ?? "").length !== 0,
    setShouldReset: action((state, payload) => {
      localStorage.setItem("shouldReset", payload ? "1" : "");
      state.shouldReset = payload;
    }),

    login: thunk(async (actions, payload, { injections }) => {
      actions.setLoginError(null);
      try {
        const response = await injections.gachaApi.login(payload.username, payload.password);
        actions.setShouldReset((response.reset ?? 0) !== 0);
        actions.setJWT(response.jwt || null);
      } catch(error) {
        // Failed to login
        actions.setLoginError(error.message);
      }
    }),
    setLoginError: action((state, payload) => {
      state.loginError = payload;
    }),

    getUnits: thunk(async (actions, payload, { injections }) => {
      return injections.gachaApi.getUnits(payload);
    }),

    attach: thunk(async (actions, payload, { injections }) => {
      return injections.gachaApi.attach(payload.jwt, payload.gachaOrVoucher, payload.other, payload.otherType);
    }),

    detach: thunk(async (actions, payload, { injections }) => {
      return injections.gachaApi.detach(payload.jwt, payload.gachaOrVoucher, payload.other, payload.otherType);
    }),

    droprates: thunk(async (actions, payload, { injections }) => {
      return injections.gachaApi.droprates(payload.jwt, payload.unit, payload.chances);
    }),

    vendset: thunk(async (actions, payload, { injections }) => {
      return injections.gachaApi.vendset(payload.jwt, payload.unit, payload.settings);
    }),

    pushRedelivery: thunk(async (actions, payload, { injections }) => {
      return injections.gachaApi.pushRedelivery(payload.jwt, payload.unit, payload.oldItem, payload.newItem, payload.fnc);
    }),

    shares: thunk(async (actions, payload, { injections }) => {
      return injections.gachaApi.shares(payload.jwt, payload.unit, payload.shares);
    }),

    deleteUnit: thunk(async (actions, payload, { injections }) => {
      return injections.gachaApi.deleteUnit(payload.jwt, payload.id);
    }),

    setPrice: thunk(async (actions, payload, { injections }) => {
      return injections.gachaApi.setPrice(payload.jwt, payload.id, payload.price);
    }),

    setUuidAlias: thunk(async (actions, payload, { injections }) => {
      return injections.gachaApi.setAliases(payload.jwt, payload.aliases);
    }),

    getUuidAlias: thunk(async (actions, payload, { injections }) => {
      return injections.gachaApi.getAliases(payload.jwt, payload.uuids);
    }),

    getAllUuidAlias: thunk(async (actions, payload, { injections }) => {
      return injections.gachaApi.getAliases(payload.jwt, ["all"]);
    }),

    setVoucherItem: thunk(async (actions, payload, { injections }) => {
      return injections.gachaApi.setVoucherItem(payload.jwt, payload.id, payload.item, payload.as_name);
    }),

    getRedeliveries: thunk(async (actions, payload, { injections }) => {
      return injections.gachaApi.getRedeliveries(payload.jwt);
    }),

    redeliverItem: thunk(async (actions, payload, { injections }) => {
      return injections.gachaApi.redeliverItem(payload.jwt, payload.item);
    }),

    toggleOwnerOnly: thunk(async (actions, payload, { injections }) => {
      return injections.gachaApi.toggleOwnerOnly(payload.jwt, payload.id, payload.enabled);
    }),

    toggleDisabled: thunk(async (actions, payload, { injections }) => {
      return injections.gachaApi.toggleDisabled(payload.jwt, payload.id, payload.enabled);
    }),

    getAnalytics: thunk(async (actions, payload, { injections }) => {
      return injections.gachaApi.getAnalytics(payload);
    }),

    getUnitNames: thunk(async (actions, payload, { injections }) => {
      return injections.gachaApi.getUnitNames(payload.jwt, payload.units);
    }),

    resetPass: thunk(async (actions, payload, { injections }) => {
      return injections.gachaApi.resetPass(payload.jwt, payload.pw, payload.cur_pw).then(() => actions.setShouldReset(false));
    }),

    savePos: thunk(async (actions, payload, { injections }) => {
      return injections.gachaApi.savePos(payload.jwt, payload.id, payload.pos);
    }),
  } as ApiModel;
}

export default createApiModel;