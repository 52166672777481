import React from 'react';
import styled, { css } from 'styled-components';
import { useStore } from '../../store';
import { truncateDecimals } from '../../util/math';
import { StyledProps } from '../../util/styled-types';

export const StatusBar = () => {
  const selection = useStore(state => state.diagram.selection);
  const zoom = useStore(state => state.editor.zoom.percent);

  const selectedText = selection.length === 0 ? 'No selection' : `Selection: ${selection.length}`;

  return (
    <Container>
      <Item fixedWidth="5rem">{selectedText}</Item>
      {/* <Item>•</Item> */}
      <Item>Zoom: {truncateDecimals(zoom * 100, 1)}%</Item>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid ${({ theme }: StyledProps) => theme.colors.menu.border};
  border-right: 1px solid ${({ theme }: StyledProps) => theme.colors.menu.border};
  border-top-right-radius: 1rem;
  background-color: ${({ theme }: StyledProps) => theme.colors.menu.background};
  color: ${({ theme }: StyledProps) => theme.colors.menu.text};
  height: 1.5rem;
  padding: 0 1rem;
  width: 15rem;
`;

interface ItemProps { fixedWidth?: string; }
const Item = styled.span`
  ${({ fixedWidth }: ItemProps) => fixedWidth ? css`
    width: ${fixedWidth};
  ` : null}
`;